@import "countdown.less";


@font-face {
    font-family: 'icons';
    src: url('../fonts/icomoon.eot');
    src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
         url('../fonts/icomoon.woff') format('woff'),
         url('../fonts/icomoon.ttf') format('truetype');
    font-weight: normal !important;
    font-style: normal;
    speak: none;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

////////////////////////////////////////////////////////////////////////////////
// colors

@black-bg: #1a1a1a;
@dark-bg: #262626;
@medium-bg: #939393;
@light-bg:  #fafafa;

@shadow: darken(@dark-bg, 5%);

@text: #373a3c;
@light-grey: #eceeef;
@grey-hover: #e6e6e6;

////////////////////////////////////////////////////////////////////////////////
// helpers
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

////////////////////////////////////////////////////////////////////////////////
// layout

.page-wrapper {
    padding: 0.75rem 0 0 0;
    position: relative;
    width: 100%;
    height: 100%;
    font-family: arial, sans-serif !important;
}

.page-wrapper {
    h1 { font-size: 2.875em; font-weight: bold;}
    h2 { font-size: 2em; font-weight: bold;}
    h3 { font-size: 1.25em; font-weight: bold; }
    h4 { font-size: 1.25em; font-weight: 500; }
    p a { color: inherit; text-decoration: underline; }

    h1,h2,h3,h4,h5,h6 { margin-bottom: 1rem; }

    h1 a, h2 a {
        &:hover {
            text-decoration: none;
        }
    }
}

body.right-to-left {
    .primary-content-holder { float: right; }
    .sidebar-holder { float: left; }
    .align-with-text { float: right; }
}

.page-wrapper:before, .page-footer:before, .section-border:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.75rem;
}
.page-footer:before { height: 0.35rem; }

.banner-img, .billboard .glitter_page_blocktype_imageblock {
    img {
        width: 100%;
        height: auto;
    }
}
.intro, .page-content {
    border-top: 1px solid @light-grey;
    background: #fff;
}

.container {
    width: 100%;
    &.narrow {
        max-width: 576px;
        @media all and (min-width: 768px) {
            max-width: 720px;
        }
    }
}

#maincontent {
  @media all and (max-width: 991px) {
    padding-top: 0 !important;
    .row {
      display: flex;
      flex-direction: column;

      > .primary-content-holder  {
        order: 2;
      }
      > .sidebar-holder {
        order: 1;
        padding: 0;
      }
    }
  }
  @media all and (max-width: 576px) {
    #iframe_form.shadow-block {
      border: none;
      box-shadow: none;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// helper classes

.hidden { display: none; visibility: hidden; }
.pos-static { position: static !important; }
.pos-relative { position: static !important; }
.no-border { border: none !important; }
.overflow-hidden { overflow: hidden; }
.flex-vert-center { display: flex; align-items: center; }
.flex-row-nowrap { display: flex; flex-flow: row nowrap }
.flex-row-wrap { display: flex; flex-flow: row wrap }
.align-self-right { margin-left: auto; }
.align-self-left { margin-right: auto; }
.font-size-md { font-size: 18px !important; }
.dark-link { color: @dark-bg; }
.dark-link:hover { color: inherit; }
.underlined { text-decoration: underline; }
.hidden-elems > div { display: none; }
.hidden-elems .shown-on-load { display: block; }
.section {
    position: relative;
    background: @grey-hover;
}

////////////////////////////////////////////////////////////////////////////////
// icons

.icon:before {
    font-family: 'icons';
    display: inline-block;
    vertical-align: inherit;
    font-weight: normal;
    margin-right: 0.5em;
}
body.right-to-left {
    .icon:before {
        margin-left: 0.5em;
        margin-right: 0;
        float: right;
    }
}

.change-location:before { content: '\e901'; }
.change-language:before { content: '\e902'; }
.whatsapp-icon:before { content: '\e930'; }
.facebook-icon:before { content: '\e931'; }
.twitter-icon:before { content: '\e932'; }
.youtube-icon:before { content: '\e933'; }
.instagram-icon:before { content: '\e934'; }
.tumblr-icon:before { content: '\e935'; }
.vimeo-icon:before { content: '\e936'; }
.flickr-icon:before { content: '\e937'; }
.email-icon:before { content: '\e938'; }
.paypal-icon:before { content: '\e939'; }
.social-icon:before { margin-right: 0.25em; }

////////////////////////////////////////////////////////////////////////////////
// header

#cookie-notice {
    padding: 1rem;
    background: @black-bg;
    color: #fff;
    position: fixed;
    bottom: 0; left: 0;
    width: 100%;
    z-index: 2000;
}

.header {
    .change-location:before, .change-language:before {
        margin-right: 0.125em;
        vertical-align: bottom;
    }
    .navbar { -webkit-font-smoothing: antialiased; }
    .container > .navbar {
        .navbar-brand { flex-grow: 10; }
        .navbar-nav {
            margin-left: auto;
            padding-right: 0;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
    .dropdown-menu {
        right: -1rem;
        margin-top: 0;
        font-size: 1.125rem;

        body.right-to-left & {
            right: auto;
            left: -1rem;
        }
    }
}
.page-wrapper .dropdown-menu { border-radius: 0; }
.page-wrapper .header { background: #fff; }
.open > .nav-link { color: inherit !important; }
.dropdown-toggle:after { display: none; }

.change-language, .change-location {
    vertical-align: middle;
    padding-left: 1em;
    overflow: hidden;
    .page-footer & {
        color: #fff !important;
        border-color: @text;
    }
    + .dropdown-menu {
        .dropdown-item.active {
            background: @light-bg;
            color: inherit;
        }
        li {
            a { color: inherit; text-align: center; }
        }
    }
    body.right-to-left & {
        padding-left: 0;
        padding-right: 1rem;
        border: none;
        border-right: 2px solid @grey-hover;
    }
}

.change-location {
    vertical-align: middle;
    + .dropdown-menu {
        width: 101vw;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        .dropdown-item { box-sizing: border-box; }
        .location-options {
            justify-content: center;
            overflow: hidden;
            list-style: none;
            padding-left: 0;
            li {
                width: 25%;
                float: left;
            }
        }
    }
}

.change-language {
    border-left: 2px solid @grey-hover;
}

@media all and (max-width: 991px) {
    .header, .page-footer {
        .change-location, .change-language {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            &:before {
                margin-right: 0;
                font-size: 1.125em;
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// common elems

.btn { white-space: normal }

.btn-outline {
    border: 2px solid lightgrey;
}

.btn-facebook {
    background: #3b5998;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.02em;
    position: relative;
    padding: 1.125rem 4.25rem 1.125rem 1rem;
    width: 100%;
    text-align: left;
    margin-bottom: 0.75rem;

    &:hover { background: lighten(#3b5998, 5%); }

    &:before {
        content: '\e931';
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #8b9dc3;
        font-family: 'icons';
        vertical-align: inherit;
        font-weight: normal;
        position: absolute;
        right: -1px; top: -1px; bottom: -1px;
        width: 3.25rem;
        text-align: center;
        font-size: 1.5rem;
    }
}

.logo, .custom-logo {
    height: 35px;
    width: 92px;
    .page-footer & {
        float: right;
    }
}

.custom-logo {
    height: 50px;
    width: auto;
    img {
        height: 100%;
        width: auto;
    }
}


.bg-img {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    max-height: none;
    height: auto;
    width: auto;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.img-fluid, figure img { width: 100%; height: auto; }
.card .campaign-link {
    margin-bottom: 0;
    + .petition-statistics { margin-top: 1.5rem; }
}
.share-bar .icon:before { color: #fff; margin-right: 0.325em; }
.share-bar {
    padding: 0.325rem 0.75rem;
    color: #fff;
    font-size: 1.625em;
    background: @medium-bg;
    .share-bar-content {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .share-bar-title {
            margin-right: 0;
        }
        .share-bar-links {
            margin-left: auto;
            flex-shrink: 0;
        }
        body.right-to-left & {
            .share-bar-title { margin-left: 1.5rem; }

            .share-bar-links {
                margin-left: 0;
                margin-right: auto;
            }
        }
    }
    &.follow { background: @dark-bg }
    a:last-of-type:before { margin-right: 0; }
}
.shadow-block {
    border: 1px solid @grey-hover;
    box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.1125);
}
.btn-lg { font-size: 1.75rem; padding: 0.375rem 1.25rem; }

.flip-clock-wrapper ul li a {
    div div.inn { background-color: @dark-bg; }
    div.up:after { background-color: @dark-bg; }
}


////////////////////////////////////////////////////////////////////////////////
// petiton styles

.petition-statistics {
    font-size: 18px;
    .card-footer & .petition-statistics { padding: 0 1rem 1rem; }
}

.progress { height: 0.475em; border-radius: 0; }
.progress[value] { border-radius: 0; }
.progress[value]::-webkit-progress-value  { border-radius: 0; }
.progress[value]::-moz-progress-bar { border-radius: 0; }

body.right-to-left .progress {
    transform: rotate(180deg);
}

#glitter_column_intro + .petition-statistics { margin: 1.5rem 0; }

.social-profiles {
    margin: 1rem 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    li {
        display: inline-block; margin: 0 0.325rem;
        a {
            font-size: 1.25em;
            color: inherit;
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// glitter blocks / columns

.glitter_page_block + .glitter_page_block { margin-top: 1.5rem; }

.glitter_page_blocktype_imageblock {

    &.image-share-block {
        .imageblock-image-wrapper {
            position: relative;
        }
        .image-block-share {
            width: 30px;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
            a {
                display: flex;
                width: 30px;
                height: 30px;
                width: 100%;
                align-items: center;
                justify-content: center;
                text-align: center;
                background: @dark-bg;
                color: #fff;
                &:hover { background: lighten(@dark-bg, 5%) }
                &:first-of-type { border-bottom: 1px solid @medium-bg; }
                span {
                    vertical-align: middle;
                    &:before { margin-right: 0; }
                }
            }
            @media all and (min-width: 768px) {
                width: 3rem;
                left: -1.5rem;
                a {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }
    }
}

.glitter_page_blocktype_redactor {
    h1,h2,h3,h4,h5 {
        color: @text;
        del { text-decoration: none; }
    }
}

#glitter_column_form_footer {
    margin-top: 1.5rem;
}

.single-col-content {
    padding: 5rem 15px 3rem 15px;
    text-align: center;
    .single-col-wrapper {
        display: inline-block;
        width: 100%;
        max-width: 680px;
    }
    #glitter_column_content .glitter_page_block, #iframe_form {
        width: 100%;
        text-align: left;
    }
}

.glitter_page_blocktype_bannerblock {
    display: flex !important;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    .single-col-content & {
        width: 100vw;
        margin: 0 0 3rem -15px;
        max-width: none !important;
    }
    .btn {
        white-space: normal;
        width: calc(100% ~"-" 30px);
        max-width: 480px;
        margin: 8rem 0;
        @media all and (max-width: 560px) {
            margin: 5rem 0;
        }
    }
    &.border-bottom {
        border-bottom: 0.75rem solid @dark-bg;
    }
}

.glitter_page_blocktype_bannerblock_no_image {
    height: auto;
    min-height: auto;
}

.glitter_page_blocktype_videotextblock {
    position: relative;
    color: #fff;
    a { color: inherit; }
    video {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .video-text-video-holder { overflow: hidden; }
    .video-text-content-holder {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        margin: 2rem 0;
        position: relative;
        z-index: 100;
        .video-text-content {
            width: 100%;
            display: block;
            margin: 2rem 0 5rem 0;
        }
        .call-to-action {
            width: 100%;
            align-self: flex-end;
            .row {
                &.text-left { text-align: left; }
                &.text-right { text-align: right; }
                &.text-center { text-align: center; }
                > div {
                    float: none;
                    display: inline-block;
                    text-align: center;
                }
            }
            h2 {
                display: inline-block;
                color: #fff;
            }
        }
    }
    .filter-bg {
        content: '';
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background: fade(@black-bg, 30%);
    }
    @media all and (min-width: 768px) {
        #glitter_column_header & {
            .video-text-content-holder {
                min-height: 32.5vh;
            }
        }
    }
    // hide videos on tablets / mobile
    @media all and (max-device-width: 1024px) {
        .fallback-image {
            display: block;
            visibility: visible;
        }
        .video-text-video {
            display: none !important;
            visibility: hidden !important;
        }
    }
}
////////////////////////////////////////////////////////////////////////////////
// forms

#iframe_form {
    background: #fff;
    .col-md-8 {
        width: 100%;
        .container { padding: 0 2.25rem 1.5rem 2.25rem; }
    }
    /*  typography  */
    .form-check { font-size: 13px; line-height: 19px; }
    > .container-fluid {
        padding: 0 1.5rem 1.5rem;
        .container { max-width: none; }
         @media all and (max-width: 767px) {
            padding: 0 1.25rem 1.25rem 1.25rem;
        }
    }

    h5 { font-weight: normal; }
    small.text-muted {
        display: block; font-size: 13px; line-height: 19px;
        a { color: @dark-bg; text-decoration: underline; }
    }
    .colour-bar {
        margin: 0 0 1.5rem 0;
        padding: 0.25em 0;
        color: #fff;
        background: darken(@medium-bg, 10%);
        text-transform: uppercase;
        + .banner-img { margin-top: -1.5rem; }
    }
    .header { background: @dark-bg; color: #fff; }
    .header .navbar-brand { font-size: 2em; }

    .list-group-item {
        border: none;
        border-top: 1px solid @medium-bg;
        position: relative;
        padding-left: 2rem;
    }
    .list-group-item {
        &:nth-of-type(1) { border-top: none; }
        &:nth-of-type(2) { opacity: 0.55; }
        &:nth-of-type(3) { opacity: 0.3; }
        &:nth-of-type(4) { opacity: 0.2; }
        &:nth-of-type(5) { opacity: 0.1; }
        svg {
            display: inline-block;
            position: absolute;
            top: 50%; left: 0;
            transform: translateY(-50%);
            height: 1.25em; width: 1.25em;
        }
        body.right-to-left & {
            svg {
                left: auto;
                right: 0;
            }
        }
    }

    form {
        input, select { box-sizing: border-box; }
        input { position: relative; }
        input:not([type=checkbox]):not([type=radio]), select, textarea {
            appearance:none;
            display: block;
            width: 100%;
            padding: 1.5rem .75rem 0.25rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            background-color: #fff;
            background-image: none;
            border: 1px solid @medium-bg;
            outline: none;
            border-radius: 0;
            color: @text;
        }
        button[type="submit"] {
            appearance:none;
            display: block;
            width: 100%;
            font-size: 2em;
            padding: 0.375rem 1.25rem;
            margin-top: 1.5rem;
            border: none;
            border-radius: 0;
            color: #fff;
            @media all and (max-width: 767px) {
                font-size: 1.75rem;
            }
        }
        select { height: 3.375rem; }
        input#mp_name { pointer-events: none; user-select: none; background: lighten(@grey-hover, 6.25%); }
        input:focus {
            z-index: 5;
        }
        select::-ms-expand { display: none; }
        .checkbox label {
            padding-left: 1.5rem;
            margin-left: -15px;
            position: relative;

            .checkboxinput {
                position: absolute;
                left: 0;
                top: 0.25rem;
            }
        }
        .pre-field-content {
            margin-left: -15px;
            margin-right: -15px;
        }
        .form-control-feedback { color: red; }
        .form-builder-field { margin-bottom: 0.75rem; }
        .form-group {
            position: relative;
            margin-top: -1px;
            margin-bottom: 0;
            &.has-danger label {
                position: absolute;
                top: 0.5em;
                font-size: 0.8em;
                max-width: calc(100% ~"-" 6.5rem);
                ~ .form-control-feedback {
                    position: absolute;
                    top: 0.5em;
                    font-size: 0.8em;
                }
            }
            .form-radio-holder {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                label {
                    position: static !important;
                    pointer-events: all !important;
                    font-size: inherit !important;
                }
            }
            label, .form-control-feedback {
                position: absolute;
                margin-bottom: 0;
                top: 1em;
                z-index: 10;
                left: 0.75rem;
                pointer-events: none;
                user-select: none;
                transition: all 0.15s;
                width: calc(100% ~"-" 1.5rem);
                &.active {
                    position: absolute;
                    top: 0.5em;
                    font-size: 0.8em;
                }
            }
            .form-control-feedback {
                margin: 0;
                position: absolute;
                width: auto;
                top: 1em;
                left: auto;
                right: 1em;
                z-index: 100;
                pointer-events: none;
            }
            + .checkbox {
                margin: 1rem 0;
                .form-text { display: none; }
            }
        }
        .form-radio-buttons {
            display: flex;
            flex-flow: row nowrap;
            margin-left: 1rem;

            input {
                position: absolute;
                height: 1px; width: 1px;
                overflow: hidden;
                clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
                clip: rect(1px, 1px, 1px, 1px);
            }

            label {
                display: inline-flex;
                min-height: 100%;
                align-items: center;
                width: auto;
                padding: 0.5rem 1rem;
                background: @light-grey;
                border: 1px solid @light-bg;
                cursor: pointer;
            }

            .radio-option + .radio-option {
                margin-left: 1rem;
            }

            input:checked + label {
                background: darken(@medium-bg, 25%);
                color: #fff;
                cursor: pointer;
            }
        }
        .form-group.has-danger {
            .form-radio-buttons label {
                max-width: none;
                font-size: inherit;
                color: inherit;
            }
            &.radio-select-field .help-block {
                display: none;
            }
        }
        .select-dropdown:after, .form-group.required:after {
            font-family: 'icons';
            content: '\e908';
            display: inline-block;
            position: absolute;
            top: 50%; right: 1rem;
            transform: translateY(-50%);
            -ms-transform: translateY(-50%);
        }
        .form-group.required:after {
            background: none;
            content: '*';
            color: @dark-bg;
            left: auto; right: -1.125rem;
            font-size: 2em;
            text-align: right;
        }
        .address-lookup-field {
            .formbuilder-address label {
                display: none;
            }
            .formbuilder-address .textarea {
                display: none;
                padding: 0.75rem !important;
            }

            &.address-lookup-active {
                .formbuilder-address .textarea {
                    display: block;
                    background: @light-bg;
                }

                .address-lookup-parent {
                    margin-bottom: 0;
                }
            }
            .form-control-feedback {
                position: static !important;
                margin: 0.5em 1em !important;
            }
        }
        .address-results-holder {
            position: absolute;
            width: 100%;
            max-height: 10rem;
            overflow: hidden;
            left: 0;
            top: 3.375rem;
            background: @medium-bg;
            color: #fff;
            z-index: 15;
            font-weight: bold;
        }
        .form-builder-field.address-lookup-parent {
            position: relative;

            textarea {
                resize: none;
            }

            .address-results-holder {
                top: 10.875rem;
            }


        }
        .address-results {
            height: 100%;
            max-height: 10rem;
            padding: 0;
            margin: 0;
            list-style: none;
            font-size: 13px;
            overflow-y: auto;
        }
        .address-results li {
            padding: 0.5rem 0.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.hovered {
                background: @light-bg;
                color: @black-bg;
                cursor: pointer;
            }
            &:not(:last-of-type) { border-bottom: 1px solid darken(@light-bg,5%); }
            &:hover {
                background: @light-bg;
                color: @black-bg;
                cursor: pointer;
            }
        }
        .form-text { margin: 0.5em 1em; }
    }

    .social-form-fill {
        display: flex;
        flex-flow: column nowrap;
        margin-left: -15px;
        margin-right: -15px;
    }

    #social-login-status {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 1rem;

        img {
            width: 70px;
            height: 70px;
            flex-shrink: 0;
            margin-right: 0.75rem;
            border: 2px solid @grey-hover;
        }
    }

    .social-choice {
        text-align: center;
        position: relative;
        margin-bottom: 1rem;

        span {
            display: inline-block;
            text-align: center;
            padding: 0.5rem 0.75rem;
            background: #fff;
            position: relative;
            text-transform: uppercase;
            font-weight: 600;
        }

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background: @grey-hover;
            position: absolute;
            left: 0; right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    /*  validation  */
    .has-danger input, input.parsley-error, #select_error_handler.parsley-error select {
        border-color: red;
        z-index: 5;
    }
    .form-group.has-danger label { color: red; }
    .checkbox .has-danger label { color: @text; }
    #select_error_handler.parsley-error {
        &:after { color: red; }
        select { color: red; }
    }
    input.parsley-error + label, #select_error_handler.parsley-error select + label { color: red !important; }

    /*  errors  */

    .alert {
        padding: 0;
        border: none;
        background: lighten(red, 50%);
        .alert-header {
            padding: 0.75rem;
            background: red;
            color: #fff;
            h5 {
                font-weight: 900;
                position: relative;
                padding: 0.625rem 0.5rem 0.625rem 3.25rem;
                &:before {
                    content: '!';
                    width: 2.5rem; height: 2.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0.0125em; left: 0;
                    background: #fff;
                    border-radius: 50%;
                    color: red;
                    font-size: 2em;
                }
            }
        }
        .alert-error {
            display: inline-block;
            color: red;
            span { color: @black-bg; }
            + .alert-error { margin-left: 0.75rem; }
        }
        ul {
            padding-left: 0;
            li {
                padding: 0.5rem 0.75rem;
                ul {
                    display: inline-block;
                    li { display: inline-block; }
                }
            }
            > li + li {
                border-top: 1px solid red;
            }
        }
    }

    /*  responsive  */
    @media all and (max-width: 767px) {
        .header .navbar-brand, h2, .btn-lg { font-size: 1.75em; }
    }
    @media all and (max-width: 320px) {
        .header .navbar-brand, h2, .btn-lg { font-size: 26px; }
        .font-size-md { font-size: 16px !important; }

    }
}


////////////////////////////////////////////////////////////////////////////////
// footer

.page-footer {
    position: relative;
    background: @dark-bg;
    color: lighten(@text, 7.5%);
    box-shadow: 0px 500px 0px 500px @dark-bg;
    img { max-height: 45px; }
    .dropup .dropdown-menu { top: auto; }
    .navbar { -webkit-font-smoothing: antialiased; }

    body.right-to-left & {
        .navbar-contact-us {
            ul { padding: 0; }
            .align-self-right {
                margin-left: 0 !important;
                margin-right: auto;
            }
        }
        .dropup .dropdown-menu {
            right: auto;
            left: 0;
        }
    }
}
.page-footer {
    .dropdown-menu { bottom: ~"calc(100% + (1.5rem - 2px))"; }
    .social-nav a:first-of-type { margin-left: 0.5em; }
    hr { border-top: 2px solid @text; }
    a:hover { text-decoration: none; }
    a, .navbar-contact-us {
        font-size: 18px;
        color: @medium-bg;
    }
    .footer-legal-text {
        font-size: 80%;

        a {
            font-size: 1em;
        }
    }
    body.right-to-left & {
        .social-nav a {
            float: left;
            margin: 0 0.25em 0 0;
            &:last-of-type { margin-right: 0.75em; }
        }
        .logo-legal-bar {
            small { direction: rtl; }
            @media all and (min-width: 544px) {
                display: flex;
                flex-flow: row nowrap;
                .col-sm-2 {
                    width: auto !important;
                    flex-shrink: 0;
                }
                .col-sm-10 { width: 100% !important; }
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// hub page

.campaign-grid {
    margin: 0 !important;
}

.campaign-grid__item {
    width: 100%;

    > div {
        width: 100% !important;
        float: none !important;
        margin: 0;
        padding: 0;
    }

    @media all and (min-width: 992px) {
        width: 291px;
    }

    @media all and (min-width: 1201px) {
        width: 347px;
    }
}

////////////////////////////////////////////////////////////////////////////////
// responsive

@media all and(max-width: 1200px) {
    .share-bar {
        padding: 0.4125rem 0.75rem;
        font-size: 1.5em;
    }
}
@media all and (min-width: 768px) {
    /* pipe next to brand logo */
    .page-wrapper .header .navbar:first-of-type > .navbar-brand small {
        margin-left: 1em;
        padding-left: 1em;
        border-left: 2px solid @grey-hover;

        body.right-to-left & {
            margin-right: 1em;
            padding-right: 1em;
            border: none;
            border-right: 2px solid @grey-hover;
        }
    }
}
@media all and (max-width: 991px) {
    .card { display: block; margin-bottom: 2rem; }
    .change-location + .dropdown-menu .location-options li { width: 33.33333%; }
}
@media all and (max-width: 767px) {
    .p-a-3 { padding: 1rem !important; }
    .page-wrapper .navbar { padding: .5rem 0; }
    .change-location + .dropdown-menu .location-options li { width: 100%; }
    .card { border: none; padding: 0 !important; }
}
@media all and (max-width: 544px) {
    .size-sm-xs { width: 100px; }
}
@media all and (max-width: 420px) {
    .share-bar { font-size: 1em; }
}
