@import "frontend-generic.less";
@import "oxfam-form-styles.less";

@font-face {
    font-family: 'headline';
    src: url('../fonts/oxfamglobalheadline-webfont.eot');
    src: url('../fonts/oxfamglobalheadline-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oxfamglobalheadline-webfont.woff2') format('woff2'),
         url('../fonts/oxfamglobalheadline-webfont.woff') format('woff'),
         url('../fonts/oxfamglobalheadline-webfont.ttf') format('truetype'),
         url('../fonts/oxfamglobalheadline-webfont.svg#oxfam_global_headlineregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'tstar-bold';
    src: url('../fonts/tstar-bold.eot');
    src: url('../fonts/tstar-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/tstar-bold.woff') format('woff'),
         url('../fonts/tstar-bold.ttf') format('truetype'),
         url('../fonts/tstar-bold.svg#oxfam_global_headlineregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tstar-headline';
    src: url('../fonts/tstar-headline-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


////////////////////////////////////////////////////////////////////////////////
// colors

@brand-primary: #61a534;
@brand-action: #e60052;
@brand-share: #0b9cdb;
@brand-bg: #e7eadb;
@text: #373a3c;

////////////////////////////////////////////////////////////////////////////////
// layout

.page-wrapper { background: @brand-bg; }
.page-wrapper {
    h1 { font-family: 'headline'; }
    h2 { font-family: 'headline'; color: @brand-primary; }
    h3 { font-family: 'tstar-bold', Arial, sans-serif; }
    h4 { font-size: 1.25em; font-weight: 500; color: @brand-primary; }
    h1 a, h2 a { color: @text; }
    &:hover { color: lighten(@text,5%); }
    @media all and (max-width: 767px) {
        h1 { font-size: 2em; font-weight: bold;}
        h2 { font-size: 1.75em; font-weight: bold;}
        h3 { font-size: 1.5em; font-weight: bold; }
        h4 { font-size: 1.15em; font-weight: 500; }
    }
}

.page-wrapper:before, .page-footer:before, .section-border:before {
    background: #fff url(../img/footer-bg.png) repeat-x bottom center/1024px;
}

.section-border-dark:before { background: @dark-bg; }


////////////////////////////////////////////////////////////////////////////////
// typography

.brand-font { font-family: 'headline', Arial, sans-serif !important; }
.brand-secondary-font { font-family: 'tstar-bold', Arial, sans-serif !important; }


////////////////////////////////////////////////////////////////////////////////
// header, footer, brand
.header .change-location:before, .header .change-language:before { color: @brand-primary; }

.change-location + .dropdown-menu .location-options {
    li {
        color: @brand-primary;
        a:hover { background: @brand-primary; color: #fff; }
        &.active { background: @light-bg; }
    }
}
.change-language + .dropdown-menu {
    color: @brand-primary;
    .dropdown-item:hover, .dropdown-item.active:hover {
        background: @brand-primary; color: #fff;
    }
}

.page-wrapper {
    .navbar-brand {
        font-size: inherit;
        + .navbar-brand {
            line-height: 30px;
            padding-left: 1rem;
            border-left: 1px solid @light-grey;
        }
    }
}

.change-location {
    border-left: 2px solid @grey-hover;
    body.right-to-left .header & {
        border: none;
        padding-left: 0; padding-right: 1rem;
        border-right: 2px solid @grey-hover;
    }
}

////////////////////////////////////////////////////////////////////////////////
// common elems

.logo {
    background: url(../img/logo.svg) center center/100% no-repeat;
    .page-footer & {
        background: url(../img/logo-w.svg) center center/100% no-repeat;
    }
}

.btn { border-radius: 0 !important; }
.btn-primary {
    .brand-font;
    background: @brand-action;
    border-color: @brand-action;
    &:hover, &:active, &:active:hover, &:active:focus, &:focus {
        background: darken(@brand-action, 5%);
        border-color: darken(@brand-action, 5%);
    }
}

.petition-statistics {
    font-family: 'tstar-bold', Arial, sans-serif;
    small { color: @brand-action; span { color: @medium-bg; } }
}
.progress[value]::-webkit-progress-value  { background-color: @brand-action; }
.progress[value]::-moz-progress-bar { background-color: @brand-action; }

body.right-to-left .progress {
    transform: rotate(180deg);
}

.card-block { background: #fff; }
.card-img-top { border-bottom: 3px solid @dark-bg; }
.card-header { background: @dark-bg; color: #fff; }
.card-footer { background: #fff; }

.share-bar { background: @brand-share; }

.flip-clock-wrapper ul li a {
    div div.inn { background-color: @brand-primary; }
    div.up:after { background-color: @brand-primary; }
}


#iframe_form {
    form .form-control-feedback { color: @brand-action; }
    .has-danger input, input.parsley-error, #select_error_handler.parsley-error select {
        border-color: @brand-action !important;
        z-index: 5;
    }
    .colour-bar { background: @brand-primary; }
    .form-group.has-danger label { color: @brand-action; }
    .checkbox .has-danger label { color: @text; }
    #select_error_handler.parsley-error {
        &:after { color: @brand-action; }
        select { color: @brand-action; }
    }
    input.parsley-error + label, #select_error_handler.parsley-error select + label {
        color: @brand-action !important;
    }
    .alert {
        background: lighten(@brand-action, 50%);
        .alert-header { background: @brand-action; }
        h5:before { color: @brand-action; }
        .alert-error { color: @brand-action; }
        ul > li + li { border-top: 1px solid @brand-action; }
    }
    .address-results li {
        background: @brand-primary;
        border-color: darken(@brand-primary, 5%) !important;
        &:hover {
            background: lighten(@brand-primary,10%);
            color: @black-bg;
            cursor: pointer;
        }
    }
}

body#iframe_form {
    .nav.navbar-nav {
        flex-flow: row wrap;

        .logo {
            background: url(../img/logo-w.svg) center center/100% no-repeat;
        }
    }
}

.glitter_page_blocktype_redactor {
    h1,h2,h3,h4,h5 {
        color: @brand-primary;
        del { color: @text; }
    }
    h1,h2,h3 { font-weight: 800; }
    h1 { font-size: 2.75em }
    h2 { font-size: 2.25em; }
    h3 { font-size: 2em; font-family: 'headline', sans-serif; }
    h4 { font-size: 1.8em; }
    @media all and (max-width: 767px) {
        h1 { font-size: 2em; font-weight: bold;}
        h2 { font-size: 1.75em; font-weight: bold;}
        h3 { font-size: 1.5em; font-weight: bold; }
        h4 { font-size: 1.15em; font-weight: 500; }
    }
}

.glitter_page_blocktype_bannerblock.border-bottom {
    border-color: @brand-primary;
}

.glitter_page_blocktype_imageblock.image-share-block {
    .image-block-share a {
        background: @brand-primary;
        color: #fff;
        &:hover { background: darken(@brand-primary, 5%); }
        &:first-of-type { border-bottom: 1px solid lighten(@brand-primary, 5%); }
    }
}

.glitter_page_blocktype_videotextblock {
    .video-text-content-holder {
        h2 a { color: inherit; }
        .call-to-action .row > div:after {
            content: '\e900';
            font-family: 'icons';
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-size: 1.25rem;
            transform: translateX(-50%);
            background: @brand-primary;
            color: #fff;
            width: 3rem; height: 3rem;
            bottom: -3.5rem; left: 50%;
            border-radius: 50%;
            @media all and (max-width: 480px) {
                width: 2rem; height: 2rem;
                font-size: 1rem;
                bottom: -3rem;
            }
        }
    }
}

///// OXFAM THEMES

#iframe_form.iframe-theme-behindthebarcodes {

    .navbar-brand .logo,
    .asteriskField {
        display: none;
    }

    .header .navbar-brand,
    .btn-primary,
    h1, h2 ,h3 ,h4 ,h5 {
        font-family: 'tstar-headline', sans-serif !important;
        text-transform: uppercase;
    }

    .header .navbar-brand,
    .btn-primary {
        font-size: 2.5rem;
    }

    .required-form-group:not(.radio-select-field):not(.checkbox) {
        position: relative;

        &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 2rem;
            right: -1.125rem;
            font-size: 2rem;
            transform: translateY(-50%);
            vertical-align: middle;
            opacity: 0.5;
        }
    }

    .formbuilder-social-facebook .form-control-feedback {
        top: -0.5rem;
        right: -0.125rem;
        font-size: 0.8rem;
    }
}

