@brand-primary: #61a534;
@brand-action: #e60052;
@brand-text: #373a3c;
@dark-grey: #333;
@light-bg: #e7eadc;

/*  layout helpers  */
.hidden-elems > div {
    display: none;
}
.hidden-elems .shown-on-load {
    display: block;
}

/*  standard & donation embed/lightbox buttons */
.oxfam_form_button {
    appearance: none;
    display: block;
    width: 100%;
    font-size: 2em;
    padding: 0.375rem 1.25rem;
    background: @brand-action;
    border: none;
    border-radius: 0;
    color: #fff;
    font-family: "headline", Arial, sans-serif;
}

#donate_lightbox_button,
.donate-button {
    .oxfam_form_button;
}

#donate_lightbox_close {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5em;
    height: 1.5em;
    font-size: 1rem;
    padding: 0;
    background: #fff;
    color: @brand-text;
}

/*  common elems / header  */

#iframe_form {
    > .container-fluid {
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
    .col-md-8 {
        width: 100%;
        .container {
            padding: 0 2.25rem 1.5rem 2.25rem;
        }
    }
    /*  typography  */
    .form-check {
        font-size: 13px;
        line-height: 19px;
    }
    > .container-fluid {
        padding: 0 1.5rem 1.5rem;
        .container {
            max-width: none;
        }
    }

    h5 {
        font-weight: normal;
    }
    small.text-muted {
        display: block;
        font-size: 13px;
        line-height: 19px;
        a {
            color: #000;
            text-decoration: underline;
        }
    }
    .colour-bar {
        margin: 0 0 1.5rem 0;
        padding: 0.25em 0;
        background: @brand-primary;
        color: #fff;
        text-transform: uppercase;
        + .banner-img {
            margin-top: -1.5rem;
        }
    }
    .header {
        background: #262626;
        color: #fff;
    }
    .green-bar h5 {
        margin-bottom: 0;
    }
    .header .navbar-brand {
        font-size: 2em;
    }
    .dropdown-toggle:after {
        display: none;
    }
    .header .change-language {
        padding-left: 1em;
        border-left: 2px solid #e5e5e5;
    }
    .btn-lg {
        font-size: 2em;
        padding: 0.375rem 1.25rem;
    }
    .share-bar .icon:before {
        color: #fff;
        margin-right: 0.25em;
    }
    .share-bar {
        padding: 0.325rem 0.75rem;
        background: #0b9cdb;
        color: #fff;
        font-size: 1.75em;
    }

    /*  petition styles  */
    .petition-statistics small {
        color: @brand-action;
    }
    .progress {
        height: 0.475em;
    }
    .progress[value]::-webkit-progress-value {
        background-color: @brand-action;
    }
    .progress[value]::-moz-progress-bar {
        background-color: @brand-action;
    }
    .form-group {
        margin-bottom: 0;
    }

    /*  icons  */
    .facebook-icon:before,
    .twitter-icon:before,
    .youtube-icon:before,
    .email-icon:before {
        margin-right: 0.25em;
    }

    /*  signees  */
    .list-group-item {
        border: none;
        border-top: 1px solid #ccc;
        position: relative;
        padding-left: 2rem;
    }
    .list-group-item {
        &:nth-of-type(1) {
            border-top: none;
        }
        &:nth-of-type(2) {
            opacity: 0.55;
        }
        &:nth-of-type(3) {
            opacity: 0.3;
        }
        &:nth-of-type(4) {
            opacity: 0.2;
        }
        &:nth-of-type(5) {
            opacity: 0.1;
        }
        svg {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 1.25em;
            width: 1.25em;
            .tick {
                fill: @brand-action;
            }
        }
        body.right-to-left & {
            padding-left: 0;
            padding-right: 2rem;
            svg {
                left: auto;
                right: 0;
            }
        }
    }

    /*  form styles  */

    form {
        input,
        select {
            box-sizing: border-box;
        }
        input {
            position: relative;
        }
        input:not([type="checkbox"]):not([type="radio"]),
        select,
        textarea {
            appearance: none;
            display: block;
            width: 100%;
            padding: 1.5rem 0.75rem 0.25rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            outline: none;
            border-radius: 0;
            color: #333;
        }

        button[type="submit"] {
            .oxfam_form_button;
        }

        input#mp_name {
            pointer-events: none;
            user-select: none;
            background: lighten(#e5e5e5, 6.25%);
        }
        input:focus {
            border-color: @brand-primary;
            z-index: 5;
        }
        select::-ms-expand {
            display: none;
        }
        .checkbox label {
            margin-left: -15px;
            position: relative;
            color: @dark-grey;
        }
        .form-group {
            position: relative;
            margin-top: 0;
            margin-bottom: 0.75rem;
            + .checkbox {
                margin: 1rem 0;
                .form-text {
                    display: none;
                }
            }
        }
        .form-control-feedback {
            color: @brand-action;
            font-family: "tstar-bold", Arial, sans-serif;
        }
        .form-group {
            label {
                position: absolute;
                margin-bottom: 0;
                top: 1em;
                z-index: 10;
                left: 0.75rem;
                color: @dark-grey;
                pointer-events: none;
                user-select: none;
                transition: all 0.15s;
                &.active {
                    position: absolute;
                    top: 0.5em;
                    font-size: 0.8em;
                }
            }
            .form-control-feedback {
                margin: 0;
                position: absolute;
                top: 1em;
                right: 1em;
                z-index: 100;
                pointer-events: none;
            }

            &.formbuilder-social-facebook .form-control-feedback {
                top: -0.5rem;
                right: -0.125rem;
                font-size: 0.8rem;
            }
        }
        .select-dropdown:after,
        .form-group.required:after {
            font-family: "icons";
            content: "\e908";
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            -ms-transform: translateY(-50%);
        }
        .form-group.required:after {
            background: none;
            content: "*";
            color: @dark-grey;
            left: auto;
            right: -1.125rem;
            font-size: 2em;
            text-align: right;
        }
        .address-results-holder {
            position: absolute;
            width: 100%;
            max-height: 10rem;
            overflow: hidden;
            left: 0;
            top: 3.375rem;
            background: @brand-primary;
            color: #fff;
            z-index: 15;
            font-weight: bold;
        }
        .address-results {
            height: 100%;
            max-height: 10rem;
            padding: 0;
            margin: 0;
            list-style: none;
            font-size: 13px;
            overflow-y: auto;
        }
        .address-results li {
            padding: 0.5rem 0.75rem;
            &:not(:last-of-type) {
                border-bottom: 1px solid #58962f;
            }
            &:hover {
                background: #f5f5f5;
                color: #111;
                cursor: pointer;
            }
        }
        .form-text {
            margin: 0.5em 1em;
        }
        body.right-to-left & {
            .form-group {
                .form-control,
                input,
                textarea,
                select {
                    text-align: right;
                }
                label {
                    left: auto;
                    right: 0.75rem;
                }
            }
            .checkbox {
                input {
                    float: right;
                    margin: 0.325em 0 0 0.5em;
                }
            }
        }
    }

    /*  validation  */
    .has-danger input,
    input.parsley-error,
    #select_error_handler.parsley-error select {
        border-color: @brand-action;
        z-index: 5;
    }
    .form-group.has-danger label {
        color: @brand-action;
    }
    .checkbox .has-danger label {
        color: #333;
    }
    #select_error_handler.parsley-error {
        &:after {
            color: @brand-action;
        }
        select {
            color: @brand-action;
        }
    }
    input.parsley-error + label,
    #select_error_handler.parsley-error select + label {
        color: @brand-action !important;
    }

    /*  errors  */

    .alert {
        padding: 0;
        border: none;
        background: lighten(@brand-action, 50%);
        .alert-header {
            padding: 0.75rem;
            background: @brand-action;
            color: #fff;
            h5 {
                font-weight: 900;
                position: relative;
                padding: 0.625rem 0.5rem 0.625rem 3.25rem;
                &:before {
                    content: "!";
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0.0125em;
                    left: 0;
                    background: #fff;
                    border-radius: 50%;
                    color: @brand-action;
                    font-size: 2em;
                }
            }
        }
        .alert-error {
            display: inline-block;
            color: @brand-action;
            span {
                color: @brand-text;
            }
            + .alert-error {
                margin-left: 0.75rem;
            }
        }
        ul {
            padding-left: 0;
            li {
                padding: 0.5rem 0.75rem;
                ul {
                    display: inline-block;
                    li {
                        display: inline-block;
                    }
                }
            }
            > li + li {
                border-top: 1px solid @brand-action;
            }
        }
    }

    /*  responsive  */
    @media all and (max-width: 1200px) {
        .share-bar {
            padding: 0.4125rem 0.75rem;
            font-size: 1.5em;
        }
    }
    @media all and (max-width: 991px) {
        .banner-img {
            max-height: none;
            min-height: auto;
        }
    }
    @media all and (max-width: 768px) {
        .header .navbar-brand,
        h2,
        .btn-lg {
            font-size: 1.75em;
        }
    }
    @media all and (max-width: 320px) {
        .header .navbar-brand,
        h2,
        .btn-lg {
            font-size: 26px;
        }
        .font-size-md {
            font-size: 16px !important;
        }
    }
}
