.flip-clock-wrapper {
  -webkit-user-select: none;
  font: normal 22px Arial, sans-serif;
  margin: 1.5rem 0 2.5rem 0;
  position: relative;
  text-align: center;
  width: 100%;
  * {
    backface-visibility: hidden;
  }
  a {
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: #ffffff;
    }
  }
  ul {
    background: #000000;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    height: 40px;
    line-height: 87px;
    list-style: none;
    margin: 2px;
    padding: 4px 0.625em;
    position: relative;
    width: 22px;
    &:nth-last-child(2) {
      margin-left: 0.5em;
    }
    &:nth-last-child(5) {
      margin-left: 0.5em;
    }
    &:nth-last-child(8) {
      margin-left: 0.5em;
    }
    li {
      height: 100%;
      left: 0;
      line-height: 40px;
      position: absolute;
      text-decoration: none !important;
      top: 0;
      width: 100%;
      z-index: 1;
      &:first-child {
        z-index: 2;
      }
      a {
        -moz-perspective: 200px;
        -webkit-perspective: 200px;
        cursor: default !important;
        display: block;
        height: 100%;
        margin: 0 !important;
        overflow: visible !important;
        perspective: 200px;
        div {
          font-size: 20px;
          height: 50%;
          left: 0;
          outline: 1px solid transparent;
          overflow: hidden;
          position: absolute;
          width: 100%;
          z-index: 1;
          .shadow {
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 2;
          }
          div.inn {
            background-color: #61a534;
            color: #ffffff;
            font-size: 20px;
            height: 200%;
            left: 0;
            position: absolute;
            text-align: center;
            width: 100%;
            z-index: 1;
          }
        }
        div.up {
          top: 0;
          transform-origin: 50% 100%;
          &:after {
            background-color: #61a534;
            content: "";
            height: 3px;
            left: 0;
            position: absolute;
            top: 44px;
            width: 100%;
            z-index: 5;
          }
          div.inn {
            top: 0;
          }
        }
        div.down {
          bottom: 0;
          transform-origin: 50% 0;
          div.inn {
            bottom: 0;
          }
        }
      }
    }
    li.flip-clock-active {
      z-index: 3;
    }
  }
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
  ul.play {
    li.flip-clock-before {
      z-index: 3;
      .up {
        animation: turn2 0.5s linear both;
        z-index: 2;
        .shadow {
          animation: show 0.5s linear both;
          background: -moz-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, @shadow 100%);
          background: -ms-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, @shadow 100%);
          background: -o-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, @shadow 100%);
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(29, 49, 15, 0.1)), color-stop(100%, @shadow));
          background: linear, to bottom, rgba(29, 49, 15, 0.1) 0%, @shadow 100%;
          background: linear, top, rgba(29, 49, 15, 0.1) 0%, @shadow 100%;
        }
      }
      .down {
        .shadow {
          animation: show 0.5s linear both;
          background: -moz-linear-gradient(top, @shadow 0%, rgba(29, 49, 15, 0.1) 100%);
          background: -ms-linear-gradient(top, @shadow 0%, rgba(29, 49, 15, 0.1) 100%);
          background: -o-linear-gradient(top, @shadow 0%, rgba(29, 49, 15, 0.1) 100%);
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @shadow), color-stop(100%, rgba(29, 49, 15, 0.1)));
          background: linear, to bottom, @shadow 0%, rgba(29, 49, 15, 0.1) 100%;
          background: linear, top, @shadow 0%, rgba(29, 49, 15, 0.1) 100%;
        }
      }
    }
    li.flip-clock-active {
      animation: asd 0.5s 0.5s linear both;
      z-index: 5;
      .down {
        animation: turn 0.5s 0.5s linear both;
        z-index: 2;
        .shadow {
          animation: hide 0.5s 0.2s linear both;
          background: -moz-linear-gradient(top, @shadow 0%, rgba(29, 49, 15, 0.1) 100%);
          background: -ms-linear-gradient(top, @shadow 0%, rgba(29, 49, 15, 0.1) 100%);
          background: -o-linear-gradient(top, @shadow 0%, rgba(29, 49, 15, 0.1) 100%);
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @shadow), color-stop(100%, rgba(29, 49, 15, 0.1)));
          background: linear, to bottom, @shadow 0%, rgba(29, 49, 15, 0.1) 100%;
          background: linear, top, @shadow 0%, rgba(29, 49, 15, 0.1) 100%;
        }
      }
      .up {
        .shadow {
          animation: hide 0.5s 0.3s linear both;
          background: -moz-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, @shadow 100%);
          background: -ms-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, @shadow 100%);
          background: -o-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, @shadow 100%);
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(29, 49, 15, 0.1)), color-stop(100%, @shadow));
          background: linear, to bottom, rgba(29, 49, 15, 0.1) 0%, @shadow 100%;
          background: linear, top, rgba(29, 49, 15, 0.1) 0%, @shadow 100%;
        }
      }
    }
  }
}

.flip-clock-wrapper.clearfix {
  *zoom: 1;
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}
.flip-clock-meridium {
  background: none !important;
  box-shadow: 0 0 0 !important;
  font-size: 36px !important;
  a {
    color: #ffffff;
  }
}

.flip-clock-divider {
  position: relative;
  &:after {
    display: inline-block;
    position: absolute;
    font-size: 16px;
    bottom: -1.25em;
    left: 1.625em;
  }
  &.days:after { content: 'day'; left: 1em;  }
  &.hours:after { content: 'hrs'; }
  &.minutes:after { content: 'min'; }
  &.seconds:after { content: 'sec'; }
}

.clock-lg .flip-clock-divider.days:after { left: 1.9em; }

.flip-clock-dot, .flip-clock-label {
  display: none;
}

@-webkit-keyframes asd {
  0% {z-index: 2; }
  20% {z-index: 4; }
  100% {z-index: 4; }
}
@-moz-keyframes asd {
  0% {z-index: 2; }
  20% {z-index: 4; }
  100% {z-index: 4; }
}
@-o-keyframes asd {
  0% {z-index: 2; }
  20% {z-index: 4; }
  100% {z-index: 4; }
}
@keyframes asd {
  0% {z-index: 2; }
  20% {z-index: 4; }
  100% {z-index: 4; }
}
@-webkit-keyframes turn {
  0% {-webkit-transform: rotateX(90deg); }
  100% {-webkit-transform: rotateX(0deg); }
}
@-moz-keyframes turn {
  0% {-moz-transform: rotateX(90deg); }
  100% {-moz-transform: rotateX(0deg); }
}
@-o-keyframes turn {
  0% {-o-transform: rotateX(90deg); }
  100% {-o-transform: rotateX(0deg); }
}
@keyframes turn {
  0% {transform: rotateX(90deg); }
  100% {transform: rotateX(0deg); }
}
@-webkit-keyframes turn2 {
  0% {-webkit-transform: rotateX(0deg); }
  100% {-webkit-transform: rotateX(-90deg); }
}
@-moz-keyframes turn2 {
  0% {-moz-transform: rotateX(0deg); }
  100% {-moz-transform: rotateX(-90deg); }
}
@-o-keyframes turn2 {
  0% {-o-transform: rotateX(0deg); }
  100% {-o-transform: rotateX(-90deg); }
}
@keyframes turn2 {
  0% {transform: rotateX(0deg); }
  100% {transform: rotateX(-90deg); }
}
@-webkit-keyframes show {
  0% {opacity: 0; }
  100% {opacity: 1; }
}
@-moz-keyframes show {
  0% {opacity: 0; }
  100% {opacity: 1; }
}
@-o-keyframes show {
  0% {opacity: 0; }
  100% {opacity: 1; }
}
@keyframes show {
  0% {opacity: 0; }
  100% {opacity: 1; }
}
@-webkit-keyframes hide {
  0% {opacity: 1; }
  100% {opacity: 0; }
}
@-moz-keyframes hide {
  0% {opacity: 1; }
  100% {opacity: 0; }
}
@-o-keyframes hide {
  0% {opacity: 1; }
  100% {opacity: 0; }
}
@keyframes hide {
  0% {opacity: 1; }
  100% {opacity: 0; }
}
