.flip-clock-wrapper {
  -webkit-user-select: none;
  font: normal 22px Arial, sans-serif;
  margin: 1.5rem 0 2.5rem 0;
  position: relative;
  text-align: center;
  width: 100%;
}
.flip-clock-wrapper * {
  backface-visibility: hidden;
}
.flip-clock-wrapper a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
.flip-clock-wrapper a:hover {
  color: #ffffff;
}
.flip-clock-wrapper ul {
  background: #000000;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  line-height: 87px;
  list-style: none;
  margin: 2px;
  padding: 4px 0.625em;
  position: relative;
  width: 22px;
}
.flip-clock-wrapper ul:nth-last-child(2) {
  margin-left: 0.5em;
}
.flip-clock-wrapper ul:nth-last-child(5) {
  margin-left: 0.5em;
}
.flip-clock-wrapper ul:nth-last-child(8) {
  margin-left: 0.5em;
}
.flip-clock-wrapper ul li {
  height: 100%;
  left: 0;
  line-height: 40px;
  position: absolute;
  text-decoration: none !important;
  top: 0;
  width: 100%;
  z-index: 1;
}
.flip-clock-wrapper ul li:first-child {
  z-index: 2;
}
.flip-clock-wrapper ul li a {
  -moz-perspective: 200px;
  -webkit-perspective: 200px;
  cursor: default !important;
  display: block;
  height: 100%;
  margin: 0 !important;
  overflow: visible !important;
  perspective: 200px;
}
.flip-clock-wrapper ul li a div {
  font-size: 20px;
  height: 50%;
  left: 0;
  outline: 1px solid transparent;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.flip-clock-wrapper ul li a div .shadow {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.flip-clock-wrapper ul li a div div.inn {
  background-color: #61a534;
  color: #ffffff;
  font-size: 20px;
  height: 200%;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
}
.flip-clock-wrapper ul li a div.up {
  top: 0;
  transform-origin: 50% 100%;
}
.flip-clock-wrapper ul li a div.up:after {
  background-color: #61a534;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  top: 44px;
  width: 100%;
  z-index: 5;
}
.flip-clock-wrapper ul li a div.up div.inn {
  top: 0;
}
.flip-clock-wrapper ul li a div.down {
  bottom: 0;
  transform-origin: 50% 0;
}
.flip-clock-wrapper ul li a div.down div.inn {
  bottom: 0;
}
.flip-clock-wrapper ul li.flip-clock-active {
  z-index: 3;
}
.flip-clock-wrapper:before {
  content: " ";
  display: table;
}
.flip-clock-wrapper:after {
  clear: both;
  content: " ";
  display: table;
}
.flip-clock-wrapper ul.play li.flip-clock-before {
  z-index: 3;
}
.flip-clock-wrapper ul.play li.flip-clock-before .up {
  animation: turn2 0.5s linear both;
  z-index: 2;
}
.flip-clock-wrapper ul.play li.flip-clock-before .up .shadow {
  animation: show 0.5s linear both;
  background: -moz-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, #191919 100%);
  background: -ms-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, #191919 100%);
  background: -o-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, #191919 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(29, 49, 15, 0.1)), color-stop(100%, #191919));
  background: linear, to bottom, rgba(29, 49, 15, 0.1) 0%, #191919 100%;
  background: linear, top, rgba(29, 49, 15, 0.1) 0%, #191919 100%;
}
.flip-clock-wrapper ul.play li.flip-clock-before .down .shadow {
  animation: show 0.5s linear both;
  background: -moz-linear-gradient(top, #191919 0%, rgba(29, 49, 15, 0.1) 100%);
  background: -ms-linear-gradient(top, #191919 0%, rgba(29, 49, 15, 0.1) 100%);
  background: -o-linear-gradient(top, #191919 0%, rgba(29, 49, 15, 0.1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #191919), color-stop(100%, rgba(29, 49, 15, 0.1)));
  background: linear, to bottom, #191919 0%, rgba(29, 49, 15, 0.1) 100%;
  background: linear, top, #191919 0%, rgba(29, 49, 15, 0.1) 100%;
}
.flip-clock-wrapper ul.play li.flip-clock-active {
  animation: asd 0.5s 0.5s linear both;
  z-index: 5;
}
.flip-clock-wrapper ul.play li.flip-clock-active .down {
  animation: turn 0.5s 0.5s linear both;
  z-index: 2;
}
.flip-clock-wrapper ul.play li.flip-clock-active .down .shadow {
  animation: hide 0.5s 0.2s linear both;
  background: -moz-linear-gradient(top, #191919 0%, rgba(29, 49, 15, 0.1) 100%);
  background: -ms-linear-gradient(top, #191919 0%, rgba(29, 49, 15, 0.1) 100%);
  background: -o-linear-gradient(top, #191919 0%, rgba(29, 49, 15, 0.1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #191919), color-stop(100%, rgba(29, 49, 15, 0.1)));
  background: linear, to bottom, #191919 0%, rgba(29, 49, 15, 0.1) 100%;
  background: linear, top, #191919 0%, rgba(29, 49, 15, 0.1) 100%;
}
.flip-clock-wrapper ul.play li.flip-clock-active .up .shadow {
  animation: hide 0.5s 0.3s linear both;
  background: -moz-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, #191919 100%);
  background: -ms-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, #191919 100%);
  background: -o-linear-gradient(top, rgba(29, 49, 15, 0.1) 0%, #191919 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(29, 49, 15, 0.1)), color-stop(100%, #191919));
  background: linear, to bottom, rgba(29, 49, 15, 0.1) 0%, #191919 100%;
  background: linear, top, rgba(29, 49, 15, 0.1) 0%, #191919 100%;
}
.flip-clock-wrapper.clearfix {
  *zoom: 1;
}
.flip-clock-wrapper.clearfix:before {
  content: " ";
  display: table;
}
.flip-clock-wrapper.clearfix:after {
  clear: both;
  content: " ";
  display: table;
}
.flip-clock-meridium {
  background: none !important;
  box-shadow: 0 0 0 !important;
  font-size: 36px !important;
}
.flip-clock-meridium a {
  color: #ffffff;
}
.flip-clock-divider {
  position: relative;
}
.flip-clock-divider:after {
  display: inline-block;
  position: absolute;
  font-size: 16px;
  bottom: -1.25em;
  left: 1.625em;
}
.flip-clock-divider.days:after {
  content: 'day';
  left: 1em;
}
.flip-clock-divider.hours:after {
  content: 'hrs';
}
.flip-clock-divider.minutes:after {
  content: 'min';
}
.flip-clock-divider.seconds:after {
  content: 'sec';
}
.clock-lg .flip-clock-divider.days:after {
  left: 1.9em;
}
.flip-clock-dot,
.flip-clock-label {
  display: none;
}
@-webkit-keyframes asd {
  0% {
    z-index: 2;
  }
  20% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}
@-moz-keyframes asd {
  0% {
    z-index: 2;
  }
  20% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}
@-o-keyframes asd {
  0% {
    z-index: 2;
  }
  20% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}
@keyframes asd {
  0% {
    z-index: 2;
  }
  20% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}
@-webkit-keyframes turn {
  0% {
    -webkit-transform: rotateX(90deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
  }
}
@-moz-keyframes turn {
  0% {
    -moz-transform: rotateX(90deg);
  }
  100% {
    -moz-transform: rotateX(0deg);
  }
}
@-o-keyframes turn {
  0% {
    -o-transform: rotateX(90deg);
  }
  100% {
    -o-transform: rotateX(0deg);
  }
}
@keyframes turn {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@-webkit-keyframes turn2 {
  0% {
    -webkit-transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(-90deg);
  }
}
@-moz-keyframes turn2 {
  0% {
    -moz-transform: rotateX(0deg);
  }
  100% {
    -moz-transform: rotateX(-90deg);
  }
}
@-o-keyframes turn2 {
  0% {
    -o-transform: rotateX(0deg);
  }
  100% {
    -o-transform: rotateX(-90deg);
  }
}
@keyframes turn2 {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-90deg);
  }
}
@-webkit-keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@font-face {
  font-family: 'icons';
  src: url('../fonts/icomoon.eot');
  src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'), url('../fonts/icomoon.woff') format('woff'), url('../fonts/icomoon.ttf') format('truetype');
  font-weight: normal !important;
  font-style: normal;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.page-wrapper {
  padding: 0.75rem 0 0 0;
  position: relative;
  width: 100%;
  height: 100%;
  font-family: arial, sans-serif !important;
}
.page-wrapper h1 {
  font-size: 2.875em;
  font-weight: bold;
}
.page-wrapper h2 {
  font-size: 2em;
  font-weight: bold;
}
.page-wrapper h3 {
  font-size: 1.25em;
  font-weight: bold;
}
.page-wrapper h4 {
  font-size: 1.25em;
  font-weight: 500;
}
.page-wrapper p a {
  color: inherit;
  text-decoration: underline;
}
.page-wrapper h1,
.page-wrapper h2,
.page-wrapper h3,
.page-wrapper h4,
.page-wrapper h5,
.page-wrapper h6 {
  margin-bottom: 1rem;
}
.page-wrapper h1 a:hover,
.page-wrapper h2 a:hover {
  text-decoration: none;
}
body.right-to-left .primary-content-holder {
  float: right;
}
body.right-to-left .sidebar-holder {
  float: left;
}
body.right-to-left .align-with-text {
  float: right;
}
.page-wrapper:before,
.page-footer:before,
.section-border:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.75rem;
}
.page-footer:before {
  height: 0.35rem;
}
.banner-img img,
.billboard .glitter_page_blocktype_imageblock img {
  width: 100%;
  height: auto;
}
.intro,
.page-content {
  border-top: 1px solid #eceeef;
  background: #fff;
}
.container {
  width: 100%;
}
.container.narrow {
  max-width: 576px;
}
@media all and (min-width: 768px) {
  .container.narrow {
    max-width: 720px;
  }
}
@media all and (max-width: 991px) {
  #maincontent {
    padding-top: 0 !important;
  }
  #maincontent .row {
    display: flex;
    flex-direction: column;
  }
  #maincontent .row > .primary-content-holder {
    order: 2;
  }
  #maincontent .row > .sidebar-holder {
    order: 1;
    padding: 0;
  }
}
@media all and (max-width: 576px) {
  #maincontent #iframe_form.shadow-block {
    border: none;
    box-shadow: none;
  }
}
.hidden {
  display: none;
  visibility: hidden;
}
.pos-static {
  position: static !important;
}
.pos-relative {
  position: static !important;
}
.no-border {
  border: none !important;
}
.overflow-hidden {
  overflow: hidden;
}
.flex-vert-center {
  display: flex;
  align-items: center;
}
.flex-row-nowrap {
  display: flex;
  flex-flow: row nowrap;
}
.flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
}
.align-self-right {
  margin-left: auto;
}
.align-self-left {
  margin-right: auto;
}
.font-size-md {
  font-size: 18px !important;
}
.dark-link {
  color: #262626;
}
.dark-link:hover {
  color: inherit;
}
.underlined {
  text-decoration: underline;
}
.hidden-elems > div {
  display: none;
}
.hidden-elems .shown-on-load {
  display: block;
}
.section {
  position: relative;
  background: #e6e6e6;
}
.icon:before {
  font-family: 'icons';
  display: inline-block;
  vertical-align: inherit;
  font-weight: normal;
  margin-right: 0.5em;
}
body.right-to-left .icon:before {
  margin-left: 0.5em;
  margin-right: 0;
  float: right;
}
.change-location:before {
  content: '\e901';
}
.change-language:before {
  content: '\e902';
}
.whatsapp-icon:before {
  content: '\e930';
}
.facebook-icon:before {
  content: '\e931';
}
.twitter-icon:before {
  content: '\e932';
}
.youtube-icon:before {
  content: '\e933';
}
.instagram-icon:before {
  content: '\e934';
}
.tumblr-icon:before {
  content: '\e935';
}
.vimeo-icon:before {
  content: '\e936';
}
.flickr-icon:before {
  content: '\e937';
}
.email-icon:before {
  content: '\e938';
}
.paypal-icon:before {
  content: '\e939';
}
.social-icon:before {
  margin-right: 0.25em;
}
#cookie-notice {
  padding: 1rem;
  background: #1a1a1a;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
}
.header .change-location:before,
.header .change-language:before {
  margin-right: 0.125em;
  vertical-align: bottom;
}
.header .navbar {
  -webkit-font-smoothing: antialiased;
}
.header .container > .navbar .navbar-brand {
  flex-grow: 10;
}
.header .container > .navbar .navbar-nav {
  margin-left: auto;
  padding-right: 0;
  flex-grow: 0;
  flex-shrink: 0;
}
.header .dropdown-menu {
  right: -1rem;
  margin-top: 0;
  font-size: 1.125rem;
}
body.right-to-left .header .dropdown-menu {
  right: auto;
  left: -1rem;
}
.page-wrapper .dropdown-menu {
  border-radius: 0;
}
.page-wrapper .header {
  background: #fff;
}
.open > .nav-link {
  color: inherit !important;
}
.dropdown-toggle:after {
  display: none;
}
.change-language,
.change-location {
  vertical-align: middle;
  padding-left: 1em;
  overflow: hidden;
}
.page-footer .change-language,
.page-footer .change-location {
  color: #fff !important;
  border-color: #373a3c;
}
.change-language + .dropdown-menu .dropdown-item.active,
.change-location + .dropdown-menu .dropdown-item.active {
  background: #e7eadc;
  color: inherit;
}
.change-language + .dropdown-menu li a,
.change-location + .dropdown-menu li a {
  color: inherit;
  text-align: center;
}
body.right-to-left .change-language,
body.right-to-left .change-location {
  padding-left: 0;
  padding-right: 1rem;
  border: none;
  border-right: 2px solid #e6e6e6;
}
.change-location {
  vertical-align: middle;
}
.change-location + .dropdown-menu {
  width: 101vw;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.change-location + .dropdown-menu .dropdown-item {
  box-sizing: border-box;
}
.change-location + .dropdown-menu .location-options {
  justify-content: center;
  overflow: hidden;
  list-style: none;
  padding-left: 0;
}
.change-location + .dropdown-menu .location-options li {
  width: 25%;
  float: left;
}
.change-language {
  border-left: 2px solid #e6e6e6;
}
@media all and (max-width: 991px) {
  .header .change-location,
  .page-footer .change-location,
  .header .change-language,
  .page-footer .change-language {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .header .change-location:before,
  .page-footer .change-location:before,
  .header .change-language:before,
  .page-footer .change-language:before {
    margin-right: 0;
    font-size: 1.125em;
  }
}
.btn {
  white-space: normal;
}
.btn-outline {
  border: 2px solid lightgrey;
}
.btn-facebook {
  background: #3b5998;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.02em;
  position: relative;
  padding: 1.125rem 4.25rem 1.125rem 1rem;
  width: 100%;
  text-align: left;
  margin-bottom: 0.75rem;
}
.btn-facebook:hover {
  background: #4264aa;
}
.btn-facebook:before {
  content: '\e931';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #8b9dc3;
  font-family: 'icons';
  vertical-align: inherit;
  font-weight: normal;
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: 3.25rem;
  text-align: center;
  font-size: 1.5rem;
}
.logo,
.custom-logo {
  height: 35px;
  width: 92px;
}
.page-footer .logo,
.page-footer .custom-logo {
  float: right;
}
.custom-logo {
  height: 50px;
  width: auto;
}
.custom-logo img {
  height: 100%;
  width: auto;
}
.bg-img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  max-height: none;
  height: auto;
  width: auto;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.img-fluid,
figure img {
  width: 100%;
  height: auto;
}
.card .campaign-link {
  margin-bottom: 0;
}
.card .campaign-link + .petition-statistics {
  margin-top: 1.5rem;
}
.share-bar .icon:before {
  color: #fff;
  margin-right: 0.325em;
}
.share-bar {
  padding: 0.325rem 0.75rem;
  color: #fff;
  font-size: 1.625em;
  background: #939393;
}
.share-bar .share-bar-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.share-bar .share-bar-content .share-bar-title {
  margin-right: 0;
}
.share-bar .share-bar-content .share-bar-links {
  margin-left: auto;
  flex-shrink: 0;
}
body.right-to-left .share-bar .share-bar-content .share-bar-title {
  margin-left: 1.5rem;
}
body.right-to-left .share-bar .share-bar-content .share-bar-links {
  margin-left: 0;
  margin-right: auto;
}
.share-bar.follow {
  background: #262626;
}
.share-bar a:last-of-type:before {
  margin-right: 0;
}
.shadow-block {
  border: 1px solid #e6e6e6;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.1125);
}
.btn-lg {
  font-size: 1.75rem;
  padding: 0.375rem 1.25rem;
}
.flip-clock-wrapper ul li a div div.inn {
  background-color: #262626;
}
.flip-clock-wrapper ul li a div.up:after {
  background-color: #262626;
}
.petition-statistics {
  font-size: 18px;
}
.card-footer .petition-statistics .petition-statistics {
  padding: 0 1rem 1rem;
}
.progress {
  height: 0.475em;
  border-radius: 0;
}
.progress[value] {
  border-radius: 0;
}
.progress[value]::-webkit-progress-value {
  border-radius: 0;
}
.progress[value]::-moz-progress-bar {
  border-radius: 0;
}
body.right-to-left .progress {
  transform: rotate(180deg);
}
#glitter_column_intro + .petition-statistics {
  margin: 1.5rem 0;
}
.social-profiles {
  margin: 1rem 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.social-profiles li {
  display: inline-block;
  margin: 0 0.325rem;
}
.social-profiles li a {
  font-size: 1.25em;
  color: inherit;
}
.glitter_page_block + .glitter_page_block {
  margin-top: 1.5rem;
}
.glitter_page_blocktype_imageblock.image-share-block .imageblock-image-wrapper {
  position: relative;
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share {
  width: 30px;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share a {
  display: flex;
  width: 30px;
  height: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #262626;
  color: #fff;
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share a:hover {
  background: #333333;
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share a:first-of-type {
  border-bottom: 1px solid #939393;
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share a span {
  vertical-align: middle;
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share a span:before {
  margin-right: 0;
}
@media all and (min-width: 768px) {
  .glitter_page_blocktype_imageblock.image-share-block .image-block-share {
    width: 3rem;
    left: -1.5rem;
  }
  .glitter_page_blocktype_imageblock.image-share-block .image-block-share a {
    width: 3rem;
    height: 3rem;
  }
}
.glitter_page_blocktype_redactor h1,
.glitter_page_blocktype_redactor h2,
.glitter_page_blocktype_redactor h3,
.glitter_page_blocktype_redactor h4,
.glitter_page_blocktype_redactor h5 {
  color: #373a3c;
}
.glitter_page_blocktype_redactor h1 del,
.glitter_page_blocktype_redactor h2 del,
.glitter_page_blocktype_redactor h3 del,
.glitter_page_blocktype_redactor h4 del,
.glitter_page_blocktype_redactor h5 del {
  text-decoration: none;
}
#glitter_column_form_footer {
  margin-top: 1.5rem;
}
.single-col-content {
  padding: 5rem 15px 3rem 15px;
  text-align: center;
}
.single-col-content .single-col-wrapper {
  display: inline-block;
  width: 100%;
  max-width: 680px;
}
.single-col-content #glitter_column_content .glitter_page_block,
.single-col-content #iframe_form {
  width: 100%;
  text-align: left;
}
.glitter_page_blocktype_bannerblock {
  display: flex !important;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.single-col-content .glitter_page_blocktype_bannerblock {
  width: 100vw;
  margin: 0 0 3rem -15px;
  max-width: none !important;
}
.glitter_page_blocktype_bannerblock .btn {
  white-space: normal;
  width: calc(100% - 30px);
  max-width: 480px;
  margin: 8rem 0;
}
@media all and (max-width: 560px) {
  .glitter_page_blocktype_bannerblock .btn {
    margin: 5rem 0;
  }
}
.glitter_page_blocktype_bannerblock.border-bottom {
  border-bottom: 0.75rem solid #262626;
}
.glitter_page_blocktype_bannerblock_no_image {
  height: auto;
  min-height: auto;
}
.glitter_page_blocktype_videotextblock {
  position: relative;
  color: #fff;
}
.glitter_page_blocktype_videotextblock a {
  color: inherit;
}
.glitter_page_blocktype_videotextblock video {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.glitter_page_blocktype_videotextblock .video-text-video-holder {
  overflow: hidden;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 2rem 0;
  position: relative;
  z-index: 100;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder .video-text-content {
  width: 100%;
  display: block;
  margin: 2rem 0 5rem 0;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder .call-to-action {
  width: 100%;
  align-self: flex-end;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder .call-to-action .row.text-left {
  text-align: left;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder .call-to-action .row.text-right {
  text-align: right;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder .call-to-action .row.text-center {
  text-align: center;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder .call-to-action .row > div {
  float: none;
  display: inline-block;
  text-align: center;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder .call-to-action h2 {
  display: inline-block;
  color: #fff;
}
.glitter_page_blocktype_videotextblock .filter-bg {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(26, 26, 26, 0.3);
}
@media all and (min-width: 768px) {
  #glitter_column_header .glitter_page_blocktype_videotextblock .video-text-content-holder {
    min-height: 32.5vh;
  }
}
@media all and (max-device-width: 1024px) {
  .glitter_page_blocktype_videotextblock .fallback-image {
    display: block;
    visibility: visible;
  }
  .glitter_page_blocktype_videotextblock .video-text-video {
    display: none !important;
    visibility: hidden !important;
  }
}
#iframe_form {
  background: #fff;
  /*  typography  */
  /*  validation  */
  /*  errors  */
  /*  responsive  */
}
#iframe_form .col-md-8 {
  width: 100%;
}
#iframe_form .col-md-8 .container {
  padding: 0 2.25rem 1.5rem 2.25rem;
}
#iframe_form .form-check {
  font-size: 13px;
  line-height: 19px;
}
#iframe_form > .container-fluid {
  padding: 0 1.5rem 1.5rem;
}
#iframe_form > .container-fluid .container {
  max-width: none;
}
@media all and (max-width: 767px) {
  #iframe_form > .container-fluid {
    padding: 0 1.25rem 1.25rem 1.25rem;
  }
}
#iframe_form h5 {
  font-weight: normal;
}
#iframe_form small.text-muted {
  display: block;
  font-size: 13px;
  line-height: 19px;
}
#iframe_form small.text-muted a {
  color: #262626;
  text-decoration: underline;
}
#iframe_form .colour-bar {
  margin: 0 0 1.5rem 0;
  padding: 0.25em 0;
  color: #fff;
  background: #7a7a7a;
  text-transform: uppercase;
}
#iframe_form .colour-bar + .banner-img {
  margin-top: -1.5rem;
}
#iframe_form .header {
  background: #262626;
  color: #fff;
}
#iframe_form .header .navbar-brand {
  font-size: 2em;
}
#iframe_form .list-group-item {
  border: none;
  border-top: 1px solid #939393;
  position: relative;
  padding-left: 2rem;
}
#iframe_form .list-group-item:nth-of-type(1) {
  border-top: none;
}
#iframe_form .list-group-item:nth-of-type(2) {
  opacity: 0.55;
}
#iframe_form .list-group-item:nth-of-type(3) {
  opacity: 0.3;
}
#iframe_form .list-group-item:nth-of-type(4) {
  opacity: 0.2;
}
#iframe_form .list-group-item:nth-of-type(5) {
  opacity: 0.1;
}
#iframe_form .list-group-item svg {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1.25em;
  width: 1.25em;
}
body.right-to-left #iframe_form .list-group-item svg {
  left: auto;
  right: 0;
}
#iframe_form form input,
#iframe_form form select {
  box-sizing: border-box;
}
#iframe_form form input {
  position: relative;
}
#iframe_form form input:not([type=checkbox]):not([type=radio]),
#iframe_form form select,
#iframe_form form textarea {
  appearance: none;
  display: block;
  width: 100%;
  padding: 1.5rem .75rem 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #939393;
  outline: none;
  border-radius: 0;
  color: #373a3c;
}
#iframe_form form button[type="submit"] {
  appearance: none;
  display: block;
  width: 100%;
  font-size: 2em;
  padding: 0.375rem 1.25rem;
  margin-top: 1.5rem;
  border: none;
  border-radius: 0;
  color: #fff;
}
@media all and (max-width: 767px) {
  #iframe_form form button[type="submit"] {
    font-size: 1.75rem;
  }
}
#iframe_form form select {
  height: 3.375rem;
}
#iframe_form form input#mp_name {
  pointer-events: none;
  user-select: none;
  background: #f6f6f6;
}
#iframe_form form input:focus {
  z-index: 5;
}
#iframe_form form select::-ms-expand {
  display: none;
}
#iframe_form form .checkbox label {
  padding-left: 1.5rem;
  margin-left: -15px;
  position: relative;
}
#iframe_form form .checkbox label .checkboxinput {
  position: absolute;
  left: 0;
  top: 0.25rem;
}
#iframe_form form .pre-field-content {
  margin-left: -15px;
  margin-right: -15px;
}
#iframe_form form .form-control-feedback {
  color: red;
}
#iframe_form form .form-builder-field {
  margin-bottom: 0.75rem;
}
#iframe_form form .form-group {
  position: relative;
  margin-top: -1px;
  margin-bottom: 0;
}
#iframe_form form .form-group.has-danger label {
  position: absolute;
  top: 0.5em;
  font-size: 0.8em;
  max-width: calc(100% - 6.5rem);
}
#iframe_form form .form-group.has-danger label ~ .form-control-feedback {
  position: absolute;
  top: 0.5em;
  font-size: 0.8em;
}
#iframe_form form .form-group .form-radio-holder {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
#iframe_form form .form-group .form-radio-holder label {
  position: static !important;
  pointer-events: all !important;
  font-size: inherit !important;
}
#iframe_form form .form-group label,
#iframe_form form .form-group .form-control-feedback {
  position: absolute;
  margin-bottom: 0;
  top: 1em;
  z-index: 10;
  left: 0.75rem;
  pointer-events: none;
  user-select: none;
  transition: all 0.15s;
  width: calc(100% - 1.5rem);
}
#iframe_form form .form-group label.active,
#iframe_form form .form-group .form-control-feedback.active {
  position: absolute;
  top: 0.5em;
  font-size: 0.8em;
}
#iframe_form form .form-group .form-control-feedback {
  margin: 0;
  position: absolute;
  width: auto;
  top: 1em;
  left: auto;
  right: 1em;
  z-index: 100;
  pointer-events: none;
}
#iframe_form form .form-group + .checkbox {
  margin: 1rem 0;
}
#iframe_form form .form-group + .checkbox .form-text {
  display: none;
}
#iframe_form form .form-radio-buttons {
  display: flex;
  flex-flow: row nowrap;
  margin-left: 1rem;
}
#iframe_form form .form-radio-buttons input {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
#iframe_form form .form-radio-buttons label {
  display: inline-flex;
  min-height: 100%;
  align-items: center;
  width: auto;
  padding: 0.5rem 1rem;
  background: #eceeef;
  border: 1px solid #e7eadc;
  cursor: pointer;
}
#iframe_form form .form-radio-buttons .radio-option + .radio-option {
  margin-left: 1rem;
}
#iframe_form form .form-radio-buttons input:checked + label {
  background: #535353;
  color: #fff;
  cursor: pointer;
}
#iframe_form form .form-group.has-danger .form-radio-buttons label {
  max-width: none;
  font-size: inherit;
  color: inherit;
}
#iframe_form form .form-group.has-danger.radio-select-field .help-block {
  display: none;
}
#iframe_form form .select-dropdown:after,
#iframe_form form .form-group.required:after {
  font-family: 'icons';
  content: '\e908';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
#iframe_form form .form-group.required:after {
  background: none;
  content: '*';
  color: #262626;
  left: auto;
  right: -1.125rem;
  font-size: 2em;
  text-align: right;
}
#iframe_form form .address-lookup-field .formbuilder-address label {
  display: none;
}
#iframe_form form .address-lookup-field .formbuilder-address .textarea {
  display: none;
  padding: 0.75rem !important;
}
#iframe_form form .address-lookup-field.address-lookup-active .formbuilder-address .textarea {
  display: block;
  background: #e7eadc;
}
#iframe_form form .address-lookup-field.address-lookup-active .address-lookup-parent {
  margin-bottom: 0;
}
#iframe_form form .address-lookup-field .form-control-feedback {
  position: static !important;
  margin: 0.5em 1em !important;
}
#iframe_form form .address-results-holder {
  position: absolute;
  width: 100%;
  max-height: 10rem;
  overflow: hidden;
  left: 0;
  top: 3.375rem;
  background: #939393;
  color: #fff;
  z-index: 15;
  font-weight: bold;
}
#iframe_form form .form-builder-field.address-lookup-parent {
  position: relative;
}
#iframe_form form .form-builder-field.address-lookup-parent textarea {
  resize: none;
}
#iframe_form form .form-builder-field.address-lookup-parent .address-results-holder {
  top: 10.875rem;
}
#iframe_form form .address-results {
  height: 100%;
  max-height: 10rem;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 13px;
  overflow-y: auto;
}
#iframe_form form .address-results li {
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#iframe_form form .address-results li.hovered {
  background: #e7eadc;
  color: #1a1a1a;
  cursor: pointer;
}
#iframe_form form .address-results li:not(:last-of-type) {
  border-bottom: 1px solid #dce0cc;
}
#iframe_form form .address-results li:hover {
  background: #e7eadc;
  color: #1a1a1a;
  cursor: pointer;
}
#iframe_form form .form-text {
  margin: 0.5em 1em;
}
#iframe_form .social-form-fill {
  display: flex;
  flex-flow: column nowrap;
  margin-left: -15px;
  margin-right: -15px;
}
#iframe_form #social-login-status {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
#iframe_form #social-login-status img {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  margin-right: 0.75rem;
  border: 2px solid #e6e6e6;
}
#iframe_form .social-choice {
  text-align: center;
  position: relative;
  margin-bottom: 1rem;
}
#iframe_form .social-choice span {
  display: inline-block;
  text-align: center;
  padding: 0.5rem 0.75rem;
  background: #fff;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
}
#iframe_form .social-choice:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #e6e6e6;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#iframe_form .has-danger input,
#iframe_form input.parsley-error,
#iframe_form #select_error_handler.parsley-error select {
  border-color: red;
  z-index: 5;
}
#iframe_form .form-group.has-danger label {
  color: red;
}
#iframe_form .checkbox .has-danger label {
  color: #373a3c;
}
#iframe_form #select_error_handler.parsley-error:after {
  color: red;
}
#iframe_form #select_error_handler.parsley-error select {
  color: red;
}
#iframe_form input.parsley-error + label,
#iframe_form #select_error_handler.parsley-error select + label {
  color: red !important;
}
#iframe_form .alert {
  padding: 0;
  border: none;
  background: #ffffff;
}
#iframe_form .alert .alert-header {
  padding: 0.75rem;
  background: red;
  color: #fff;
}
#iframe_form .alert .alert-header h5 {
  font-weight: 900;
  position: relative;
  padding: 0.625rem 0.5rem 0.625rem 3.25rem;
}
#iframe_form .alert .alert-header h5:before {
  content: '!';
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.0125em;
  left: 0;
  background: #fff;
  border-radius: 50%;
  color: red;
  font-size: 2em;
}
#iframe_form .alert .alert-error {
  display: inline-block;
  color: red;
}
#iframe_form .alert .alert-error span {
  color: #1a1a1a;
}
#iframe_form .alert .alert-error + .alert-error {
  margin-left: 0.75rem;
}
#iframe_form .alert ul {
  padding-left: 0;
}
#iframe_form .alert ul li {
  padding: 0.5rem 0.75rem;
}
#iframe_form .alert ul li ul {
  display: inline-block;
}
#iframe_form .alert ul li ul li {
  display: inline-block;
}
#iframe_form .alert ul > li + li {
  border-top: 1px solid red;
}
@media all and (max-width: 767px) {
  #iframe_form .header .navbar-brand,
  #iframe_form h2,
  #iframe_form .btn-lg {
    font-size: 1.75em;
  }
}
@media all and (max-width: 320px) {
  #iframe_form .header .navbar-brand,
  #iframe_form h2,
  #iframe_form .btn-lg {
    font-size: 26px;
  }
  #iframe_form .font-size-md {
    font-size: 16px !important;
  }
}
.page-footer {
  position: relative;
  background: #262626;
  color: #494d50;
  box-shadow: 0px 500px 0px 500px #262626;
}
.page-footer img {
  max-height: 45px;
}
.page-footer .dropup .dropdown-menu {
  top: auto;
}
.page-footer .navbar {
  -webkit-font-smoothing: antialiased;
}
body.right-to-left .page-footer .navbar-contact-us ul {
  padding: 0;
}
body.right-to-left .page-footer .navbar-contact-us .align-self-right {
  margin-left: 0 !important;
  margin-right: auto;
}
body.right-to-left .page-footer .dropup .dropdown-menu {
  right: auto;
  left: 0;
}
.page-footer .dropdown-menu {
  bottom: calc(100% + (1.5rem - 2px));
}
.page-footer .social-nav a:first-of-type {
  margin-left: 0.5em;
}
.page-footer hr {
  border-top: 2px solid #373a3c;
}
.page-footer a:hover {
  text-decoration: none;
}
.page-footer a,
.page-footer .navbar-contact-us {
  font-size: 18px;
  color: #939393;
}
.page-footer .footer-legal-text {
  font-size: 80%;
}
.page-footer .footer-legal-text a {
  font-size: 1em;
}
body.right-to-left .page-footer .social-nav a {
  float: left;
  margin: 0 0.25em 0 0;
}
body.right-to-left .page-footer .social-nav a:last-of-type {
  margin-right: 0.75em;
}
body.right-to-left .page-footer .logo-legal-bar small {
  direction: rtl;
}
@media all and (min-width: 544px) {
  body.right-to-left .page-footer .logo-legal-bar {
    display: flex;
    flex-flow: row nowrap;
  }
  body.right-to-left .page-footer .logo-legal-bar .col-sm-2 {
    width: auto !important;
    flex-shrink: 0;
  }
  body.right-to-left .page-footer .logo-legal-bar .col-sm-10 {
    width: 100% !important;
  }
}
.campaign-grid {
  margin: 0 !important;
}
.campaign-grid__item {
  width: 100%;
}
.campaign-grid__item > div {
  width: 100% !important;
  float: none !important;
  margin: 0;
  padding: 0;
}
@media all and (min-width: 992px) {
  .campaign-grid__item {
    width: 291px;
  }
}
@media all and (min-width: 1201px) {
  .campaign-grid__item {
    width: 347px;
  }
}
@media all and (max-width: 1200px) {
  .share-bar {
    padding: 0.4125rem 0.75rem;
    font-size: 1.5em;
  }
}
@media all and (min-width: 768px) {
  /* pipe next to brand logo */
  .page-wrapper .header .navbar:first-of-type > .navbar-brand small {
    margin-left: 1em;
    padding-left: 1em;
    border-left: 2px solid #e6e6e6;
  }
  body.right-to-left .page-wrapper .header .navbar:first-of-type > .navbar-brand small {
    margin-right: 1em;
    padding-right: 1em;
    border: none;
    border-right: 2px solid #e6e6e6;
  }
}
@media all and (max-width: 991px) {
  .card {
    display: block;
    margin-bottom: 2rem;
  }
  .change-location + .dropdown-menu .location-options li {
    width: 33.33333%;
  }
}
@media all and (max-width: 767px) {
  .p-a-3 {
    padding: 1rem !important;
  }
  .page-wrapper .navbar {
    padding: .5rem 0;
  }
  .change-location + .dropdown-menu .location-options li {
    width: 100%;
  }
  .card {
    border: none;
    padding: 0 !important;
  }
}
@media all and (max-width: 544px) {
  .size-sm-xs {
    width: 100px;
  }
}
@media all and (max-width: 420px) {
  .share-bar {
    font-size: 1em;
  }
}
/*  layout helpers  */
.hidden-elems > div {
  display: none;
}
.hidden-elems .shown-on-load {
  display: block;
}
/*  standard & donation embed/lightbox buttons */
.oxfam_form_button {
  appearance: none;
  display: block;
  width: 100%;
  font-size: 2em;
  padding: 0.375rem 1.25rem;
  background: #e60052;
  border: none;
  border-radius: 0;
  color: #fff;
  font-family: "headline", Arial, sans-serif;
}
#donate_lightbox_button,
.donate-button {
  appearance: none;
  display: block;
  width: 100%;
  font-size: 2em;
  padding: 0.375rem 1.25rem;
  background: #e60052;
  border: none;
  border-radius: 0;
  color: #fff;
  font-family: "headline", Arial, sans-serif;
}
#donate_lightbox_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5em;
  height: 1.5em;
  font-size: 1rem;
  padding: 0;
  background: #fff;
  color: #373a3c;
}
/*  common elems / header  */
#iframe_form {
  /*  typography  */
  /*  petition styles  */
  /*  icons  */
  /*  signees  */
  /*  form styles  */
  /*  validation  */
  /*  errors  */
  /*  responsive  */
}
#iframe_form > .container-fluid {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
#iframe_form .col-md-8 {
  width: 100%;
}
#iframe_form .col-md-8 .container {
  padding: 0 2.25rem 1.5rem 2.25rem;
}
#iframe_form .form-check {
  font-size: 13px;
  line-height: 19px;
}
#iframe_form > .container-fluid {
  padding: 0 1.5rem 1.5rem;
}
#iframe_form > .container-fluid .container {
  max-width: none;
}
#iframe_form h5 {
  font-weight: normal;
}
#iframe_form small.text-muted {
  display: block;
  font-size: 13px;
  line-height: 19px;
}
#iframe_form small.text-muted a {
  color: #000;
  text-decoration: underline;
}
#iframe_form .colour-bar {
  margin: 0 0 1.5rem 0;
  padding: 0.25em 0;
  background: #61a534;
  color: #fff;
  text-transform: uppercase;
}
#iframe_form .colour-bar + .banner-img {
  margin-top: -1.5rem;
}
#iframe_form .header {
  background: #262626;
  color: #fff;
}
#iframe_form .green-bar h5 {
  margin-bottom: 0;
}
#iframe_form .header .navbar-brand {
  font-size: 2em;
}
#iframe_form .dropdown-toggle:after {
  display: none;
}
#iframe_form .header .change-language {
  padding-left: 1em;
  border-left: 2px solid #e5e5e5;
}
#iframe_form .btn-lg {
  font-size: 2em;
  padding: 0.375rem 1.25rem;
}
#iframe_form .share-bar .icon:before {
  color: #fff;
  margin-right: 0.25em;
}
#iframe_form .share-bar {
  padding: 0.325rem 0.75rem;
  background: #0b9cdb;
  color: #fff;
  font-size: 1.75em;
}
#iframe_form .petition-statistics small {
  color: #e60052;
}
#iframe_form .progress {
  height: 0.475em;
}
#iframe_form .progress[value]::-webkit-progress-value {
  background-color: #e60052;
}
#iframe_form .progress[value]::-moz-progress-bar {
  background-color: #e60052;
}
#iframe_form .form-group {
  margin-bottom: 0;
}
#iframe_form .facebook-icon:before,
#iframe_form .twitter-icon:before,
#iframe_form .youtube-icon:before,
#iframe_form .email-icon:before {
  margin-right: 0.25em;
}
#iframe_form .list-group-item {
  border: none;
  border-top: 1px solid #ccc;
  position: relative;
  padding-left: 2rem;
}
#iframe_form .list-group-item:nth-of-type(1) {
  border-top: none;
}
#iframe_form .list-group-item:nth-of-type(2) {
  opacity: 0.55;
}
#iframe_form .list-group-item:nth-of-type(3) {
  opacity: 0.3;
}
#iframe_form .list-group-item:nth-of-type(4) {
  opacity: 0.2;
}
#iframe_form .list-group-item:nth-of-type(5) {
  opacity: 0.1;
}
#iframe_form .list-group-item svg {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1.25em;
  width: 1.25em;
}
#iframe_form .list-group-item svg .tick {
  fill: #e60052;
}
body.right-to-left #iframe_form .list-group-item {
  padding-left: 0;
  padding-right: 2rem;
}
body.right-to-left #iframe_form .list-group-item svg {
  left: auto;
  right: 0;
}
#iframe_form form input,
#iframe_form form select {
  box-sizing: border-box;
}
#iframe_form form input {
  position: relative;
}
#iframe_form form input:not([type="checkbox"]):not([type="radio"]),
#iframe_form form select,
#iframe_form form textarea {
  appearance: none;
  display: block;
  width: 100%;
  padding: 1.5rem 0.75rem 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 0;
  color: #333;
}
#iframe_form form button[type="submit"] {
  appearance: none;
  display: block;
  width: 100%;
  font-size: 2em;
  padding: 0.375rem 1.25rem;
  background: #e60052;
  border: none;
  border-radius: 0;
  color: #fff;
  font-family: "headline", Arial, sans-serif;
}
#iframe_form form input#mp_name {
  pointer-events: none;
  user-select: none;
  background: #f5f5f5;
}
#iframe_form form input:focus {
  border-color: #61a534;
  z-index: 5;
}
#iframe_form form select::-ms-expand {
  display: none;
}
#iframe_form form .checkbox label {
  margin-left: -15px;
  position: relative;
  color: #333;
}
#iframe_form form .form-group {
  position: relative;
  margin-top: 0;
  margin-bottom: 0.75rem;
}
#iframe_form form .form-group + .checkbox {
  margin: 1rem 0;
}
#iframe_form form .form-group + .checkbox .form-text {
  display: none;
}
#iframe_form form .form-control-feedback {
  color: #e60052;
  font-family: "tstar-bold", Arial, sans-serif;
}
#iframe_form form .form-group label {
  position: absolute;
  margin-bottom: 0;
  top: 1em;
  z-index: 10;
  left: 0.75rem;
  color: #333;
  pointer-events: none;
  user-select: none;
  transition: all 0.15s;
}
#iframe_form form .form-group label.active {
  position: absolute;
  top: 0.5em;
  font-size: 0.8em;
}
#iframe_form form .form-group .form-control-feedback {
  margin: 0;
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 100;
  pointer-events: none;
}
#iframe_form form .form-group.formbuilder-social-facebook .form-control-feedback {
  top: -0.5rem;
  right: -0.125rem;
  font-size: 0.8rem;
}
#iframe_form form .select-dropdown:after,
#iframe_form form .form-group.required:after {
  font-family: "icons";
  content: "\e908";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
#iframe_form form .form-group.required:after {
  background: none;
  content: "*";
  color: #333;
  left: auto;
  right: -1.125rem;
  font-size: 2em;
  text-align: right;
}
#iframe_form form .address-results-holder {
  position: absolute;
  width: 100%;
  max-height: 10rem;
  overflow: hidden;
  left: 0;
  top: 3.375rem;
  background: #61a534;
  color: #fff;
  z-index: 15;
  font-weight: bold;
}
#iframe_form form .address-results {
  height: 100%;
  max-height: 10rem;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 13px;
  overflow-y: auto;
}
#iframe_form form .address-results li {
  padding: 0.5rem 0.75rem;
}
#iframe_form form .address-results li:not(:last-of-type) {
  border-bottom: 1px solid #58962f;
}
#iframe_form form .address-results li:hover {
  background: #f5f5f5;
  color: #111;
  cursor: pointer;
}
#iframe_form form .form-text {
  margin: 0.5em 1em;
}
body.right-to-left #iframe_form form .form-group .form-control,
body.right-to-left #iframe_form form .form-group input,
body.right-to-left #iframe_form form .form-group textarea,
body.right-to-left #iframe_form form .form-group select {
  text-align: right;
}
body.right-to-left #iframe_form form .form-group label {
  left: auto;
  right: 0.75rem;
}
body.right-to-left #iframe_form form .checkbox input {
  float: right;
  margin: 0.325em 0 0 0.5em;
}
#iframe_form .has-danger input,
#iframe_form input.parsley-error,
#iframe_form #select_error_handler.parsley-error select {
  border-color: #e60052;
  z-index: 5;
}
#iframe_form .form-group.has-danger label {
  color: #e60052;
}
#iframe_form .checkbox .has-danger label {
  color: #333;
}
#iframe_form #select_error_handler.parsley-error:after {
  color: #e60052;
}
#iframe_form #select_error_handler.parsley-error select {
  color: #e60052;
}
#iframe_form input.parsley-error + label,
#iframe_form #select_error_handler.parsley-error select + label {
  color: #e60052 !important;
}
#iframe_form .alert {
  padding: 0;
  border: none;
  background: #ffe6ef;
}
#iframe_form .alert .alert-header {
  padding: 0.75rem;
  background: #e60052;
  color: #fff;
}
#iframe_form .alert .alert-header h5 {
  font-weight: 900;
  position: relative;
  padding: 0.625rem 0.5rem 0.625rem 3.25rem;
}
#iframe_form .alert .alert-header h5:before {
  content: "!";
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.0125em;
  left: 0;
  background: #fff;
  border-radius: 50%;
  color: #e60052;
  font-size: 2em;
}
#iframe_form .alert .alert-error {
  display: inline-block;
  color: #e60052;
}
#iframe_form .alert .alert-error span {
  color: #373a3c;
}
#iframe_form .alert .alert-error + .alert-error {
  margin-left: 0.75rem;
}
#iframe_form .alert ul {
  padding-left: 0;
}
#iframe_form .alert ul li {
  padding: 0.5rem 0.75rem;
}
#iframe_form .alert ul li ul {
  display: inline-block;
}
#iframe_form .alert ul li ul li {
  display: inline-block;
}
#iframe_form .alert ul > li + li {
  border-top: 1px solid #e60052;
}
@media all and (max-width: 1200px) {
  #iframe_form .share-bar {
    padding: 0.4125rem 0.75rem;
    font-size: 1.5em;
  }
}
@media all and (max-width: 991px) {
  #iframe_form .banner-img {
    max-height: none;
    min-height: auto;
  }
}
@media all and (max-width: 768px) {
  #iframe_form .header .navbar-brand,
  #iframe_form h2,
  #iframe_form .btn-lg {
    font-size: 1.75em;
  }
}
@media all and (max-width: 320px) {
  #iframe_form .header .navbar-brand,
  #iframe_form h2,
  #iframe_form .btn-lg {
    font-size: 26px;
  }
  #iframe_form .font-size-md {
    font-size: 16px !important;
  }
}
@font-face {
  font-family: 'headline';
  src: url('../fonts/oxfamglobalheadline-webfont.eot');
  src: url('../fonts/oxfamglobalheadline-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/oxfamglobalheadline-webfont.woff2') format('woff2'), url('../fonts/oxfamglobalheadline-webfont.woff') format('woff'), url('../fonts/oxfamglobalheadline-webfont.ttf') format('truetype'), url('../fonts/oxfamglobalheadline-webfont.svg#oxfam_global_headlineregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'tstar-bold';
  src: url('../fonts/tstar-bold.eot');
  src: url('../fonts/tstar-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/tstar-bold.woff') format('woff'), url('../fonts/tstar-bold.ttf') format('truetype'), url('../fonts/tstar-bold.svg#oxfam_global_headlineregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'tstar-headline';
  src: url('../fonts/tstar-headline-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.page-wrapper {
  background: #e7eadb;
}
.page-wrapper h1 {
  font-family: 'headline';
}
.page-wrapper h2 {
  font-family: 'headline';
  color: #61a534;
}
.page-wrapper h3 {
  font-family: 'tstar-bold', Arial, sans-serif;
}
.page-wrapper h4 {
  font-size: 1.25em;
  font-weight: 500;
  color: #61a534;
}
.page-wrapper h1 a,
.page-wrapper h2 a {
  color: #373a3c;
}
.page-wrapper:hover {
  color: #434749;
}
@media all and (max-width: 767px) {
  .page-wrapper h1 {
    font-size: 2em;
    font-weight: bold;
  }
  .page-wrapper h2 {
    font-size: 1.75em;
    font-weight: bold;
  }
  .page-wrapper h3 {
    font-size: 1.5em;
    font-weight: bold;
  }
  .page-wrapper h4 {
    font-size: 1.15em;
    font-weight: 500;
  }
}
.page-wrapper:before,
.page-footer:before,
.section-border:before {
  background: #fff url(../img/footer-bg.png) repeat-x bottom center / 1024px;
}
.section-border-dark:before {
  background: #262626;
}
.brand-font {
  font-family: 'headline', Arial, sans-serif !important;
}
.brand-secondary-font {
  font-family: 'tstar-bold', Arial, sans-serif !important;
}
.header .change-location:before,
.header .change-language:before {
  color: #61a534;
}
.change-location + .dropdown-menu .location-options li {
  color: #61a534;
}
.change-location + .dropdown-menu .location-options li a:hover {
  background: #61a534;
  color: #fff;
}
.change-location + .dropdown-menu .location-options li.active {
  background: #e7eadc;
}
.change-language + .dropdown-menu {
  color: #61a534;
}
.change-language + .dropdown-menu .dropdown-item:hover,
.change-language + .dropdown-menu .dropdown-item.active:hover {
  background: #61a534;
  color: #fff;
}
.page-wrapper .navbar-brand {
  font-size: inherit;
}
.page-wrapper .navbar-brand + .navbar-brand {
  line-height: 30px;
  padding-left: 1rem;
  border-left: 1px solid #eceeef;
}
.change-location {
  border-left: 2px solid #e6e6e6;
}
body.right-to-left .header .change-location {
  border: none;
  padding-left: 0;
  padding-right: 1rem;
  border-right: 2px solid #e6e6e6;
}
.logo {
  background: url(../img/logo.svg) center center / 100% no-repeat;
}
.page-footer .logo {
  background: url(../img/logo-w.svg) center center / 100% no-repeat;
}
.btn {
  border-radius: 0 !important;
}
.btn-primary {
  font-family: 'headline', Arial, sans-serif !important;
  background: #e60052;
  border-color: #e60052;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:focus {
  background: #cd0049;
  border-color: #cd0049;
}
.petition-statistics {
  font-family: 'tstar-bold', Arial, sans-serif;
}
.petition-statistics small {
  color: #e60052;
}
.petition-statistics small span {
  color: #939393;
}
.progress[value]::-webkit-progress-value {
  background-color: #e60052;
}
.progress[value]::-moz-progress-bar {
  background-color: #e60052;
}
body.right-to-left .progress {
  transform: rotate(180deg);
}
.card-block {
  background: #fff;
}
.card-img-top {
  border-bottom: 3px solid #262626;
}
.card-header {
  background: #262626;
  color: #fff;
}
.card-footer {
  background: #fff;
}
.share-bar {
  background: #0b9cdb;
}
.flip-clock-wrapper ul li a div div.inn {
  background-color: #61a534;
}
.flip-clock-wrapper ul li a div.up:after {
  background-color: #61a534;
}
#iframe_form form .form-control-feedback {
  color: #e60052;
}
#iframe_form .has-danger input,
#iframe_form input.parsley-error,
#iframe_form #select_error_handler.parsley-error select {
  border-color: #e60052 !important;
  z-index: 5;
}
#iframe_form .colour-bar {
  background: #61a534;
}
#iframe_form .form-group.has-danger label {
  color: #e60052;
}
#iframe_form .checkbox .has-danger label {
  color: #373a3c;
}
#iframe_form #select_error_handler.parsley-error:after {
  color: #e60052;
}
#iframe_form #select_error_handler.parsley-error select {
  color: #e60052;
}
#iframe_form input.parsley-error + label,
#iframe_form #select_error_handler.parsley-error select + label {
  color: #e60052 !important;
}
#iframe_form .alert {
  background: #ffe6ef;
}
#iframe_form .alert .alert-header {
  background: #e60052;
}
#iframe_form .alert h5:before {
  color: #e60052;
}
#iframe_form .alert .alert-error {
  color: #e60052;
}
#iframe_form .alert ul > li + li {
  border-top: 1px solid #e60052;
}
#iframe_form .address-results li {
  background: #61a534;
  border-color: #56922e !important;
}
#iframe_form .address-results li:hover {
  background: #79c547;
  color: #1a1a1a;
  cursor: pointer;
}
body#iframe_form .nav.navbar-nav {
  flex-flow: row wrap;
}
body#iframe_form .nav.navbar-nav .logo {
  background: url(../img/logo-w.svg) center center / 100% no-repeat;
}
.glitter_page_blocktype_redactor h1,
.glitter_page_blocktype_redactor h2,
.glitter_page_blocktype_redactor h3,
.glitter_page_blocktype_redactor h4,
.glitter_page_blocktype_redactor h5 {
  color: #61a534;
}
.glitter_page_blocktype_redactor h1 del,
.glitter_page_blocktype_redactor h2 del,
.glitter_page_blocktype_redactor h3 del,
.glitter_page_blocktype_redactor h4 del,
.glitter_page_blocktype_redactor h5 del {
  color: #373a3c;
}
.glitter_page_blocktype_redactor h1,
.glitter_page_blocktype_redactor h2,
.glitter_page_blocktype_redactor h3 {
  font-weight: 800;
}
.glitter_page_blocktype_redactor h1 {
  font-size: 2.75em;
}
.glitter_page_blocktype_redactor h2 {
  font-size: 2.25em;
}
.glitter_page_blocktype_redactor h3 {
  font-size: 2em;
  font-family: 'headline', sans-serif;
}
.glitter_page_blocktype_redactor h4 {
  font-size: 1.8em;
}
@media all and (max-width: 767px) {
  .glitter_page_blocktype_redactor h1 {
    font-size: 2em;
    font-weight: bold;
  }
  .glitter_page_blocktype_redactor h2 {
    font-size: 1.75em;
    font-weight: bold;
  }
  .glitter_page_blocktype_redactor h3 {
    font-size: 1.5em;
    font-weight: bold;
  }
  .glitter_page_blocktype_redactor h4 {
    font-size: 1.15em;
    font-weight: 500;
  }
}
.glitter_page_blocktype_bannerblock.border-bottom {
  border-color: #61a534;
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share a {
  background: #61a534;
  color: #fff;
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share a:hover {
  background: #56922e;
}
.glitter_page_blocktype_imageblock.image-share-block .image-block-share a:first-of-type {
  border-bottom: 1px solid #6cb83a;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder h2 a {
  color: inherit;
}
.glitter_page_blocktype_videotextblock .video-text-content-holder .call-to-action .row > div:after {
  content: '\e900';
  font-family: 'icons';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 1.25rem;
  transform: translateX(-50%);
  background: #61a534;
  color: #fff;
  width: 3rem;
  height: 3rem;
  bottom: -3.5rem;
  left: 50%;
  border-radius: 50%;
}
@media all and (max-width: 480px) {
  .glitter_page_blocktype_videotextblock .video-text-content-holder .call-to-action .row > div:after {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    bottom: -3rem;
  }
}
#iframe_form.iframe-theme-behindthebarcodes .navbar-brand .logo,
#iframe_form.iframe-theme-behindthebarcodes .asteriskField {
  display: none;
}
#iframe_form.iframe-theme-behindthebarcodes .header .navbar-brand,
#iframe_form.iframe-theme-behindthebarcodes .btn-primary,
#iframe_form.iframe-theme-behindthebarcodes h1,
#iframe_form.iframe-theme-behindthebarcodes h2,
#iframe_form.iframe-theme-behindthebarcodes h3,
#iframe_form.iframe-theme-behindthebarcodes h4,
#iframe_form.iframe-theme-behindthebarcodes h5 {
  font-family: 'tstar-headline', sans-serif !important;
  text-transform: uppercase;
}
#iframe_form.iframe-theme-behindthebarcodes .header .navbar-brand,
#iframe_form.iframe-theme-behindthebarcodes .btn-primary {
  font-size: 2.5rem;
}
#iframe_form.iframe-theme-behindthebarcodes .required-form-group:not(.radio-select-field):not(.checkbox) {
  position: relative;
}
#iframe_form.iframe-theme-behindthebarcodes .required-form-group:not(.radio-select-field):not(.checkbox):before {
  content: '*';
  display: inline-block;
  position: absolute;
  top: 2rem;
  right: -1.125rem;
  font-size: 2rem;
  transform: translateY(-50%);
  vertical-align: middle;
  opacity: 0.5;
}
#iframe_form.iframe-theme-behindthebarcodes .formbuilder-social-facebook .form-control-feedback {
  top: -0.5rem;
  right: -0.125rem;
  font-size: 0.8rem;
}
